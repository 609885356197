/** @file TODO: documentar */
import onmount from 'onmount';
import * as notify from '../../../../../../../../app/assets/javascripts/lib/buk/notice.js';
import Intercooler from '../../../../../../../../app/assets/javascripts/lib/intercooler.js';

onmount('#custom-reports-drag-drop', async function () {

  $('#list-categories>.nav-item').on('error.ic', function (_evt, elt, _status, _str, _xhr) {
    $('#async_category_error').removeClass('d-none');
    $(elt[0].attributes['ic-target']?.value).html('');
  });

  onmount('#list-categories .nav-link.active', async function () {
    Intercooler.triggerRequest(this.parentElement);
  });

  const { default: Sortable } = await import('sortablejs');
  const $self = $(this);

  function sorteable() {
    Sortable.create(this, {
      group: { name: 'parameters', pull: 'clone' },
      animation: 150,
      sort: false,
      filter: '.disabled',
      ghostClass: 'disabled',
      onAdd: function (evt) {
        // al momento de devolver que se destruya
        evt.item.remove();
      },
    });
  }

  function iframeInstructions(dropContainer) {
    const childrenCount = dropContainer.find('li').find('.badge').length;
    const instructionsColumns = dropContainer.find('.instructions-columns');
    if (instructionsColumns.length) {
      instructionsColumns.toggle(childrenCount === 0);
    }
  }

  // Cambiamos en index en +1 si hay un elemento en agrupar dado que cada container comienza con index 0
  function fixDraggableIndex(draggableIndex) {
    if ($self.find('.group-by-drop-container').find('li').length) {
      draggableIndex += 1;
    }
    return draggableIndex;
  }

  // Cambiamos los index desde un valor inicial secuencialmente
  // Si agregramos un chip a agrupar, los chips de columnas deben comenzar desde 1
  // Si quitamos un chip de agrupar, los chips de columnas deben comenzar desde 0
  function overWriteSecuentialOrder(chipContainer, startValue) {
    $self.find(chipContainer).find('input[name$="[order]"]').filter(function () { return $(this).val() >= 0; })
      .each(function () {
        $(this).val(startValue);
        startValue++;
      });
  }

  // Define el accionable de cerrar para crear el chip al agregarlo a un container
  const chipClose = `<span
                    data-toggle="close"
                    class="material-icons notranslate pl-1 cursor-default"
                    aria-label="Cerrar">
                      close
                  </span>`;

  // Método para construir el ícono de tipo al agregar elemento a un conteneder
  function buildIconType(eventItem) {
    const fieldIconFromChipList = $(eventItem).find('input[name$="[chip_icon]"]').val();
    const fieldIconFromContainers = $(eventItem).find('input[name="chip_icon"]').val();
    const fieldIcon = fieldIconFromChipList || fieldIconFromContainers;
    return `<span class="material-icons notranslate pr-1" aria-hidden="true">${fieldIcon}</span>`;
  }

  const attr = $self.find('#list-categories>li')?.first()?.attr('ic-get-from');
  if (typeof attr !== 'undefined' && attr !== false) {
    onmount('.drag-container', sorteable);
  }
  else {
    $self.find('.drag-container').each(sorteable);
  }

  $self.find('.drop-container').each(function () {
    const dropContainer = $self.find('.drop-container');
    iframeInstructions(dropContainer);
    Sortable.create(this, {
      group: 'parameters',
      animation: 150,
      sort: true,
      onAdd: function (evt) {
        evt.newDraggableIndex = fixDraggableIndex(evt.newDraggableIndex);
        // Escapamos las comillas, espacios y demás caracteres especiales.
        const regExp = /([ #;&,.+*~':"!^$[\]()=>|/@])/g;
        // evitamos que se dupliquen campos
        let fieldName = $(evt.item).find('input[name$="[field_name]"]').val();
        let groupName = $(evt.item).find('input[name$="[group_name]"]').val();
        $(evt.item).find('input[name$="[group_by]"]').val(false);
        fieldName = fieldName.replace(regExp, '\\$1');
        groupName = groupName.replace(regExp, '\\$1');
        //Verifica instruccion al iframe
        iframeInstructions(dropContainer);
        const count = $self.find('.drop-container').find(`input[value="${fieldName}"]`).closest('li').find(`input[value="${groupName}"]`).length;
        if (count !== 1) {
          notify.info('El campo que intenta agregar ya existe en la lista de columnas');
          setTimeout(() => {
            notify.clear();
          }, 3000);
          evt.item.remove();
          return;
        }

        //Verificamos si el campo agregado está en el container de agrupación
        const countGroupBy = $self.find('.group-by-drop-container')
          .find(`input[value="${fieldName}"]`)
          .closest('li')
          .find(`input[value="${groupName}"]`)
          .length;
        if (countGroupBy !== 0) {
          notify.info('El campo que intenta agregar ya existe en la lista de agrupar');
          setTimeout(() => {
            notify.clear();
          }, 3000);
          evt.item.remove();
          return;
        }

        $self.find('.drop-container').find('input[name$="[order]"]').filter(function () {
          return $(this).val() >= evt.newDraggableIndex;
        }).each(function () { $(this).val(+$(this).val() + 1); });
        $(evt.item).find('input[name$="[order]"]').val(evt.newDraggableIndex);

        const showCategoriesInFields = $('input#show_categories_in_fields').val() === 'true';
        if (showCategoriesInFields) {
          // Añadimos el nombre del grupo del campos al soltarlo en la zona de dropdowns
          const translatedGroup = $(evt.item).find('input[name="group_translated"]').val();
          let translatedFieldName = $(evt.item).find('.badge').html();

          $(evt.item).removeClass('li-item-name');

          // construimos el ícono de tipo
          const chipIconType = buildIconType(evt.item);

          //Si existe modal en la página
          if ($('#group-by-modal-report').length) {
            translatedFieldName = $(evt.item).find('input[name="field_name_translated"]').val();
            const chipModal = `<span class="cursor-pointer" data-toggle="modal" data-target="#group-by-modal-report">
                                ${translatedGroup} - ${translatedFieldName}
                              </span>`;
            $(evt.item).find('.badge').html(`${chipIconType} ${chipModal} ${chipClose}`);
          }
          else {
            $(evt.item).find('.badge').html(`${translatedGroup} - ${translatedFieldName} ${chipClose}`);
          }
        }
      },
      onRemove: function (evt) {
        const container = $('.group-by-drop-container');
        const hasContainer = container.length > 0;
        const groupByContainerBadgeHtml = container.find('li').find('.badge').html();
        const eventBadgeHtml = $(evt.item).find('.badge').html();
        // Si existe el contenedor de agrupar y el chip fue movido desde columnas a agrupar dejamos
        // la reponsabilidad de corregir los index al evento onAdd del contenedor agrupar
        if (!(hasContainer && groupByContainerBadgeHtml === eventBadgeHtml)) {
          $self.find('.drop-container').find('input[name$="[order]"]')
            .filter(function () { return $(this).val() > evt.oldDraggableIndex; })
            .each(function () { $(this).val(+$(this).val() - 1); });
        }
        //Verifica instruccion al iframe
        iframeInstructions(dropContainer);
      },
      onUpdate: function (evt) {
        evt.newDraggableIndex = fixDraggableIndex(evt.newDraggableIndex);
        evt.oldDraggableIndex = fixDraggableIndex(evt.oldDraggableIndex);
        if (evt.newDraggableIndex > evt.oldDraggableIndex) {
          $self.find('.drop-container').find('input[name$="[order]"]').filter(function () {
            return $(this).val() > evt.oldDraggableIndex && $(this).val() <= evt.newDraggableIndex;
          }).each(function () { $(this).val(+$(this).val() - 1); });
        }
        else {
          $self.find('.drop-container').find('input[name$="[order]"]').filter(function () {
            return $(this).val() >= evt.newDraggableIndex && $(this).val() < evt.oldDraggableIndex;
          }).each(function () { $(this).val(+$(this).val() + 1); });
        }
        $(evt.item).find('input[name$="[order]"]').val(evt.newDraggableIndex);
      },
    });
  });

  const groupByDropContainer = '.group-by-drop-container';
  $self.find(groupByDropContainer).each(function () {
    const groupByContainer = $self.find(groupByDropContainer);
    iframeInstructions(groupByContainer);
    Sortable.create(this, {
      group: 'parameters',
      animation: 150,
      onAdd: function (evt) {
        // Escapamos las comillas, espacios y demás caracteres especiales.
        const regExp = /([ #;&,.+*~':"!^$[\]()=>|/@])/g;
        // evitamos que se dupliquen campos
        let fieldName = $(evt.item).find('input[name$="[field_name]"]').val();
        let groupName = $(evt.item).find('input[name$="[group_name]"]').val();
        $(evt.item).find('input[name$="[order]"]').val(0);
        $(evt.item).find('input[name$="[group_by]"]').val(true);
        fieldName = fieldName.replace(regExp, '\\$1');
        groupName = groupName.replace(regExp, '\\$1');
        iframeInstructions(groupByContainer);
        //Verificamos si el campo agregado está en el container de columnas
        const countColumns = $self.find('.drop-container')
          .find(`input[value="${fieldName}"]`)
          .closest('li')
          .find(`input[value="${groupName}"]`)
          .length;
        if (countColumns !== 0) {
          notify.info('El campo que intenta agregar ya existe en la lista de columnas');
          setTimeout(() => {
            notify.clear();
          }, 3000);
          evt.item.remove();
          return;
        }

        //Verificamos si el campo agregado ya está en el container de agrupar
        const count = $self.find(groupByDropContainer)
          .find(`input[value="${fieldName}"]`)
          .closest('li')
          .find(`input[value="${groupName}"]`)
          .length;
        if (count !== 1) {
          notify.info('El campo que intenta agregar ya existe en la lista de agrupar');
          setTimeout(() => {
            notify.clear();
          }, 3000);
          evt.item.remove();
          return;
        }

        // Modificamos el order de los chip de columnas desde order 1 para que el chip de agrupar quede primero
        overWriteSecuentialOrder('.drop-container', 1);

        const showCategoriesInFields = $('input#show_categories_in_fields').val() === 'true';
        if (showCategoriesInFields) {
          // Añadimos el nombre del grupo del campos al soltarlo en la zona de dropdowns
          const translatedGroup = $(evt.item).find('input[name="group_translated"]').val();
          const translatedFieldName = $(evt.item).find('input[name="field_name_translated"]').val();

          // construimos el ícono de tipo
          const chipIconType = buildIconType(evt.item);

          // Se construye chip + modal
          const chipModal = `<span class="cursor-pointer" data-toggle="modal" data-target="#group-by-modal-report">
                              ${translatedGroup} - ${translatedFieldName}
                            </span>`;
          $(evt.item).find('.badge').html(`${chipIconType} ${chipModal} ${chipClose}`);

          $(evt.item).removeClass('li-item-name');
          // eliminamos elemento anterior que pueda existir y agregamos el nuevo
          $(groupByDropContainer).find('li').remove();
          $(groupByDropContainer).append(evt.item);
        }
      },
      onRemove: function () {
        // Modificamos el order de los chip de columnas desde order 0 al no tener chip en agrupar
        overWriteSecuentialOrder('.drop-container', 0);
        iframeInstructions(groupByContainer);
      },
    });
  });
  // Definir las opciones del select una sola vez
  const orderByOptions = [
    { value: '', text: 'Sin Ordenar' },
    { value: '0', text: 'Ascendente' },
    { value: '1', text: 'Descendente' },
  ];

  // Definir las opciones del select de metricas de string, date, boolean
  const metricOptionsForStringDateBoolean = [
    { value: '0', text: 'Separado por comas' },
    { value: '1', text: 'Contar' },
  ];

  // Definir las opciones del select de metricas de string, date, boolean
  const metricOptionsForNumeric = [
    { value: '1', text: 'Contar' },
    { value: '2', text: 'Media' },
    { value: '3', text: 'Sumar' },
    { value: '4', text: 'Máxima' },
    { value: '5', text: 'Mínima' },
  ];

  document.querySelector('[id*="_report_custom_report_template"]').addEventListener('click', function (event) {
    if (event.target.getAttribute('data-toggle') === 'close') {
      var dropContainer = $(event.target).closest('.drop-container, .group-by-drop-container');
      event.target.parentElement.parentElement.remove();
      if (dropContainer.length) {
        iframeInstructions(dropContainer);
      }
    }
    if (event.target.getAttribute('data-toggle') === 'modal') {
      var selectElementOrderBy = $('#group-by-modal-report .modal-content .modal-body #select_ondenar_por');
      var selectElementGroupBy = $('#group-by-modal-report .modal-content .modal-body #select_agrupar_por');

      // Guardar la id del chip en el elemento hidden del modal
      const chipId = $(event.target).parent().parent().find('input[name$="[id_modal]"]').val();
      $('#chip_id_hidden_field').val(chipId);

      // Vaciar el select antes de añadir las nuevas opciones
      selectElementOrderBy.empty();
      selectElementGroupBy.empty();
      // Añadir opciones al select de orderBy
      orderByOptions.forEach(function (option) {
        var optionElement = $('<option></option>')
          .attr('value', option.value)
          .text(option.text);
        selectElementOrderBy.append(optionElement);
      });
      const orderBy = $(event.target).parent().parent().find('input[name$="[order_by]"]').val();
      // Establecer la opción 'Sin Ordenar' como seleccionada por defecto
      selectElementOrderBy.val(orderBy);

      //Muestro el "select de agrupar por" solo si no esta en el campo de "agrupar por"
      const groupBy = $(event.target).parent().parent().find('input[name$="[group_by]"]').val();
      //Ver si fue agregado un parametro al campo "agrupar por"
      const groupByHasChildren = $self.find(groupByDropContainer).find('li').length > 0;

      if (groupBy === 'true' || !groupByHasChildren) {
        selectElementGroupBy.parent().hide();
      }
      else if (groupBy === 'false') {
        selectElementGroupBy.parent().show();
        // Depende si el tipo de dato es numerico o string, date, boolean
        // 0 -> numeric
        // 1 -> string
        // 2 -> date
        // 3 -> boolean
        const fieldType = $(event.target).parent().parent().find('input[name$="[field_type]"]').val();
        let optionsGroupBy = metricOptionsForStringDateBoolean;
        switch (fieldType) {
          case '1':
            optionsGroupBy = metricOptionsForNumeric;
            break;
          default:
            optionsGroupBy = metricOptionsForStringDateBoolean;
            break;
        }

        // Añadir opciones al select de groupBy
        optionsGroupBy.forEach(function (option) {
          var optionElement = $('<option></option>')
            .attr('value', option.value)
            .text(option.text);
          selectElementGroupBy.append(optionElement);
        });
        const fieldMetric = $(event.target).parent().parent().find('input[name$="[field_metric]"]').val();
        // Establecer metrica seleccionada por defecto
        selectElementGroupBy.val(fieldMetric);
      }
    }
  });

  //Guardar seleccion de modal
  $('[id*="confirm-modal-report"]').on('click', function (event) {
    var selectOrderBy = $('#group-by-modal-report .modal-content .modal-body #select_ondenar_por');
    var selectGroupBy = $('#group-by-modal-report .modal-content .modal-body #select_agrupar_por');
    var isGroupBySelectHidden = selectGroupBy.is(':hidden');

    //Elemento escondido
    var hiddenValue = $('#chip_id_hidden_field').val();

    //Busca los elementos con la id y les asigna el valor
    var parentElement = $(event.target).closest('[id*="_report_custom_report_template"]');

    //Guardo el valor seleccionado de order_by
    var selectorOrderBy = 'input[name*="[' + hiddenValue + ']"][name$="[order_by]"]';
    var lastOrderedElement = parentElement.find(selectorOrderBy).val(selectOrderBy.val());
    //Guardo el valor seleccionado de field_metric
    if (!isGroupBySelectHidden) {
      parentElement.find('input[name*="[' + hiddenValue + ']"][name$="[field_metric]"]').val(selectGroupBy.val());
    }

    //Obtiene los valores de campos columnas y agrupar_por para mantener el último order_by seleccionado
    const groupByDropContainerValue = $self.find(groupByDropContainer).find('input[name$="[order_by]"]');
    const dropContainerValue = $self.find('.drop-container').find('input[name$="[order_by]"]');
    const concatenatedValues = groupByDropContainerValue.add(dropContainerValue);
    concatenatedValues.each(function () {
      if (!$(this).is(lastOrderedElement)) {
        $(this).removeAttr('value');
      }
    });

    // Cierra el modal
    $('#group-by-modal-report').modal('hide');
  });

});
