/** @file Integra buk Hotjar */
import onmount from 'onmount';

const userInfo = parseJSON(document.getElementById('buk-user-info')?.text);
const hotjarInfo = parseJSON(document.getElementById('hotjar-user-info')?.text);

if (userInfo && hotjarInfo) {
  const userId = userInfo['email'] || null;
  const screenResolution = window.screen.width + 'x' + window.screen.height;

  (function (h, o, t, j, a, r) {
    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments); };
    h._hjSettings = { hjid: 3392561, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

  window.hj('identify', userId, {
    'age': userInfo['age'],
    'active_employees': userInfo['active_employees'],
    'device_platform': userInfo['device_platform'],
    'tenant': userInfo['host'],
    'normal_user': userInfo['normal_user'],
    'screen_resolution': screenResolution,
    'sex': userInfo['sex'],
    'superadmin': userInfo['superadmin'],
    'user_supervisor': userInfo['user_supervisor'],
    'tenant_country': hotjarInfo['tenant_country'],
    'profile_payroll': hotjarInfo['profile_payroll'],
    'tenant_has_payroll': hotjarInfo['tenant_has_payroll'],
    'tenant_is_implementing': hotjarInfo['tenant_is_implementing'],
  });
}

function parseJSON(json) {
  return JSON.parse(json || 'null');
}

// Function to add elements in hotjar.
$(() => {
  const cookies = document.cookie.split(';');
  const hotjarData = cookies.find(cookie => cookie.includes('hotjar'))?.split('=').pop();
  if (hotjarData) {
    const [type, tag] = hotjarData.split('-');
    window.hj(type, tag);
  }
});

onmount('[data-payroll-survey]', async function () {
  if ($(this).data('reproceso') === true) {
    window.hj('event', 'pay_repayroll');
  }
  else {
    window.hj('event', 'pay_payroll');
  }
});

onmount('#item_transfer_submit_button', async function () {
  $('#item_transfer_submit_button').on('click', () => {
    var bono = $('#item_transfer_form_bono_id').find(':selected').text();
    var transferType = $('#item_transfer_form_transfer_type').val();
    var bonoId = $('#item_transfer_form_bono_id').val();

    if (transferType === 'item_transfer' && bono.includes('gratificacion')) {
      $('.modal').modal('hide');
      window.hj('event', 'gratificacion_transfer_downloaded');
    }
    if (transferType === 'pago_cts_semestral' && bonoId) {
      $('.modal').modal('hide');
      window.hj('event', 'cts_transfer_downloaded');
    }
  });
});

onmount('#update-goal-progress-survey', async function () {
  window.hj('event', 'old_save_goal_progress');
});
