/** @file TODO: documentar*/
import onmount from 'onmount';
import 'cocoon-rails';

onmount('#role-family-specific-capacities', function () {
  const self = this;
  // first time
  validateSpecificCapacitations();
  validateLastInputWeight();
  permitSubmitButton();
  // add/remove field
  $(self).on('cocoon:after-insert cocoon:after-remove', '.association-fields', function () {
    validateSpecificCapacitations();
    validateLastInputWeight();
    permitSubmitButton();
  });
  // on change number input and select
  $(self).on('change mouseup keyup', ['input[type=number]:visible', '.role-family-select:visible'], function () {
    validateLastInputWeight();
    validateSpecificCapacitations();
    permitSubmitButton();
  });
  //Antes de remover un elemento, se quitan los atributos required del peso y codigo
  $(self).on('cocoon:before-remove', function (_e, removido) {
    var $removido = $(removido);
    $removido.find(':input[required]').prop('required', false);
  });
  // al actualizar la fila
  onmount('.role-family-specific-capacities-row', function () {
    validateSpecificCapacitations();
    validateLastInputWeight();
    permitSubmitButton();
  });

  // local functions
  function removeHasError(div) {
    if (div.children().isValid($('form.simple_form.with_nested_form').attr('data-client-side-validations'))) {
      div.removeClass('has-error');
    }
  }

  function validateLastInputWeight() {
    var selects = $(self).find('div.role-family-specific-capacities-weight-field:visible');
    if (selects.length > 0) {
      let sum = 0;
      selects.each(function () {
        sum += +$(this).children().val();
      });
      if (sum !== 100) {
        $(self).find('#role-family-specific-capacities-weight-alert').show();
        $(self).find('div.role-family-specific-capacities-weight-field:visible:last').addClass('has-error');
        $(self).find('div.role-family-specific-capacities-weight-field:visible:not(:last)').each(function () {
          removeHasError($(this));
        });
      }
      else if (sum === 100) {
        $(self).find('#role-family-specific-capacities-weight-alert').hide();
        selects.each(function () {
          removeHasError($(this));
        });
      }
    }
    else {
      $(self).find('#role-family-specific-capacities-weight-alert').hide();
    }
  }

  function validateSpecificCapacitations() {
    const selectedValues = [];
    const emptySelectMessage = '<span class="help-block">no puede estar en blanco</span>';

    $(self).find('div.form-group.role-family-specific-capacities-select:visible').map(function () {
      const value = $(this).children().val();
      if (value === '' && $(this).children().length < 2) {
        $(this).addClass('has-error');
        $(this).append(emptySelectMessage);
      }
      else if (value !== '') {
        $(this).removeClass('has-error');
        $(this).children('span').remove();
        selectedValues.push(value);
      }
    });

    const repeatedValues = selectedValues.filter((item, index) => selectedValues.indexOf(item) !== index);
    if (repeatedValues.length > 0) {
      $(self).find('#role-family-specific-capacities-unique-alert').show();
      $(self).find('div.form-group.role-family-specific-capacities-select:visible').each(function () {
        if (repeatedValues.includes($(this).children().val())) {
          $(this).addClass('has-error');
        }
      });
    }
    else {
      $(self).find('#role-family-specific-capacities-unique-alert').hide();
    }
  }

  function permitSubmitButton() {
    const showUnique = $(self).find('#role-family-specific-capacities-unique-alert').attr('style') === '';
    const showWeight = $(self).find('#role-family-specific-capacities-weight-alert').attr('style') === '';
    if (showUnique || showWeight || emptySelect()) {
      $('#role-family-edit-form-submit-button').addClass('disabled');
    }
    else {
      $('#role-family-edit-form-submit-button').removeClass('disabled');
    }
  }

  function emptySelect() {
    if ($('div.form-group.role-family-specific-capacities-select:visible').children('span').length > 0) {
      return true;
    }
    return false;
  }
});
