/** @file Script que sirve para copiar un elemento al clipboard
 *
 * Recibe el texto a copiar
 * @example
 *   <button data-copy-to-clipboard="texto"/>
 *
 * */
import onmount from 'onmount';

onmount('[data-copy-to-clipboard]', function () {
  $(this).on('click', () => {
    var copyText = $(this).data('copy-to-clipboard');
    var textArea = document.createElement('textarea');
    textArea.value = copyText;
    textArea.setAttribute('readonly', '');
    document.body.appendChild(textArea);
    textArea.select();
    try {
      // Ejecutar el comando de copiar al portapapeles
      var successful = document.execCommand('copy');
      var msg = successful ? `Se copió el enlace a: ${copyText}` : 'No se pudo copiar el texto al portapapeles';
      window.alert(msg);
    }
    catch (err) {
      // Si hay algún error, mostrar mensaje de error
      window.alert('Ocurrió un error obteniendo la URL.\nVuelva a intentarlo.');
    }
    finally {
      document.body.removeChild(textArea);
    }
  });
});
