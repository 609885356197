/** @file
 * Archivo de marcas de amplitud para workflow
 */
import onmount from 'onmount';

onmount('#create-workflow-process', async function () {
  $('#create-workflow-process').on('click', function () {
    const element = $(this);
    const importData = {
      'source': 'Workflow',
    };
    sendAmplitudeTracking(element, 'Start_Request_Workflow', importData);
  });
});

onmount('div.request-workflow-process', async function () {
  $('#request-workflow-process').on('click', function () {
    const element = $(this);
    const importData = {
      'source': 'Workflow',
      'template_id': element.attr('data-template-id'),
      'template_type': element.attr('data-template-type'),
    };
    sendAmplitudeTracking(element, 'Request_Workflow', importData);
  });

  $('#approval-workflow-process').on('click', function () {
    const element = $(this);
    const operationAltaDisplay = element.attr('data-operation-alta-display');
    const altaProcess = $('#pre_alta_operation_alta_process').find(':selected');
    const importData = {
      'source': 'Workflow',
      'template_type': 'Alta',
    };
    if (operationAltaDisplay === 'true' && altaProcess.val()) {
      sendAmplitudeTracking(element, 'Request_Workflow', importData);
    }
  });
});

onmount('div.button-create-template-workflow', async function () {
  $('#create-template-workflow').on('click', function () {
    const element = $(this);
    const importData = {};
    sendAmplitudeTracking(element, 'Start_Create_Template_Workflow', importData);
  });
});

onmount('#save-template-workflow', async function () {
  $('#save-template-workflow').on('click', function () {
    const element = $(this);
    const selectElement = $('#process_template_type').find(':selected');
    const nameElement = $('#process_template_title');
    const importData = {
      'template_type': selectElement.html(),
      'template_name': nameElement.val(),
    };
    if(selectElement.val() && nameElement.val()) {
      sendAmplitudeTracking(element, 'Create_Template_Workflow', importData);
    }
  });
});

onmount('#new_employee_modal', async function () {
  $(document).on('click', '#submit-workflow-create', function () {
    const element = $(this);
    const selectElement = $('#workflow_process_template_id').find(':selected');
    const importData = {
      'source': 'Selección',
      'template_type': element.attr('data-template-type'),
    };
    if(selectElement.val()) {
      sendAmplitudeTracking(element, 'Request_Workflow', importData);
    }
  });
});

onmount('#onboarding_process_form_footer', async function () {
  $(document).on('click', '#submit-create-onboarding', function () {
    const element = $(this);
    const importData = {
      'source': 'Onboarding',
      'template_type': 'Pre - Ingreso',
    };
    sendAmplitudeTracking(element, 'Request_Workflow', importData);
  });
});

function sendAmplitudeTracking(element, message, data) {
  element.trigger('amplitudeTracking', {
    message: message,
    data: data,
  });
}
