/** @file
* Archivo para generar actualizaciones en la sección de portadas y acciones rápidas
*/
import onmount from 'onmount';

onmount('.new_employee_portal_communication_resource, .edit_employee_portal_communication_resource', async function () {
  const form = $(this);
  const selector = form.find('#select-url-options');
  const messageUrlContent = form.find('.message-url');
  const messageFileUrlContent = form.find('.message-file-url');

  await import('@assets/javascripts/vendor/select2');
  selector.select2({ minimumResultsForSearch: Infinity, width: '100%' });

  selector.change(function () {
    const option = selector[0].options[selector[0].selectedIndex].value;
    if (option === '0') {
      messageUrlContent.hide('slow');
      messageFileUrlContent.hide('slow');
      messageFileUrlContent.find('input').val('');
      messageUrlContent.find('textarea').val('');
    }
    if (option === '1') {
      messageUrlContent.show('slow');
      messageFileUrlContent.hide('slow');
      messageFileUrlContent.find('input').val('');
    }
    if (option === '2') {
      messageUrlContent.hide('slow');
      messageFileUrlContent.show('slow');
      messageUrlContent.find('textarea').val('');
    }
  });
});
