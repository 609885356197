/** @file TODO: documentar */
import onmount from 'onmount';
import { warning } from '../../../../../../../../app/assets/javascripts/lib/buk/alert';

onmount('#administracion-panel', function () {
  changeVacationApprovalRadios();
  changeAssignItemstRadios();
  changeProcessesRadios();
  changeNominaElectronicaRadios();
  changeEmployeePermissionsRadios();
  changeFeeServicesRadios();
  changeProcessPermissionsRadios();

  $('input[name="permission_role[processes]"]').on('change', changeProcessPermissionsRadios);

  $('input[name="permission_role[empleados]"]').on('change', function () {
    changeProcessesRadios();
    changeNominaElectronicaRadios();
  });

  $('input[name="permission_role[personal_information]"]').on('change', changeEmployeePermissionsRadios);

  $('input[name="permission_role[vacation]"]').on('change', changeVacationApprovalRadios);

  $('input[name="permission_role[admin_items]"]').on('change', changeAssignItemstRadios);

  $('input[name="permission_role[fee_services]"]').on('change', changeFeeServicesRadios);

  $('input[name="permission_role[assign_items]"], input[name="permission_role[asistencia]"]')
    .on('change', changeRestrictedSettlementChangesRadios);

  $('#permission_role_historial_1').on('click', function () {
    const message = `Tenga en cuenta que como el Historial de Cambios no está afecto a otros permisos,
    ni a límite de áreas ni a visibilidad de roles, cualquier usuario asociado a este rol tendrá acceso a esta opción
    independientemente de sus otras configuraciones.`;
    warning(message);
  });

  function changeProcessesRadios() {
    const value = $("input[name='permission_role[empleados]']:checked").val();

    if (value === '0') {
      // Deshabilita opciones de trabajos. (No tiene sentido que tengas permiso de trabajo sin tener al menos lectura de emeplados)
      $('#permission_role_jobs_3').prop('disabled', true);
      $('#permission_role_jobs_2').prop('disabled', true);
      $('#permission_role_jobs_1').prop('disabled', true);
      $('#permission_role_jobs_0').prop('disabled', false);
      $('#permission_role_jobs_0').prop('checked', true);
    }
    else {
      $('#permission_role_jobs_0').prop('disabled', false);
      $('#permission_role_jobs_1').prop('disabled', false);
      $('#permission_role_jobs_2').prop('disabled', false);
      $('#permission_role_jobs_3').prop('disabled', false);
    }

    if (value === '0' || value === '1') {
      $('#permission_role_processes_1').prop('disabled', true);
      $('#permission_role_processes_0').prop('disabled', false);
      $('#permission_role_processes_0').prop('checked', true);
    }
    else if (value === '2' || value === '3') {
      $('#permission_role_processes_0').prop('disabled', false);
      $('#permission_role_processes_1').prop('disabled', false);
    }
  }

  /** Habilita/deshabilita los radio buttons de aprobacion de vacaciones */
  function changeVacationApprovalRadios() {
    const value = $("input[name='permission_role[vacation]']:checked").val();
    if(value === '0') {
      $('#permission_role_vacation_approval_1').prop('disabled', true);
      $('#permission_role_vacation_approval_0').prop('disabled', false);
      $('#permission_role_vacation_approval_0').prop('checked', true);
    }
    else if(value === '3') {
      $('#permission_role_vacation_approval_0').prop('disabled', true);
      $('#permission_role_vacation_approval_1').prop('disabled', false);
      $('#permission_role_vacation_approval_1').prop('checked', true);
    }
    else {
      $('#permission_role_vacation_approval_0').prop('disabled', false);
      $('#permission_role_vacation_approval_1').prop('disabled', false);
    }
  }

  /** Habilita/deshabilita los radio buttons de asignación de items */
  function changeAssignItemstRadios() {
    const value = $("input[name='permission_role[admin_items]']:checked").val();
    if (value === '0') {
      $('#permission_role_assign_items_3').prop('disabled', true);
      $('#permission_role_assign_items_2').prop('disabled', true);
      $('#permission_role_assign_items_1').prop('disabled', true);
      $('#permission_role_assign_items_0').prop('disabled', false);
      $('#permission_role_assign_items_0').prop('checked', true);
    }
    else {
      $('#permission_role_assign_items_0').prop('disabled', false);
      $('#permission_role_assign_items_1').prop('disabled', false);
      $('#permission_role_assign_items_2').prop('disabled', false);
      $('#permission_role_assign_items_3').prop('disabled', false);
    }
  }

  /** Habilita/deshabilita los radio buttons de nomina electronica */
  function changeNominaElectronicaRadios() {
    const value = $("input[name='permission_role[empleados]']:checked").val();
    if (value === '0' || value === '1') {
      $('#permission_role_nomina_electronica_3').prop('disabled', true);
      $('#permission_role_nomina_electronica_2').prop('disabled', true);
      $('#permission_role_nomina_electronica_1').prop('disabled', true);
      $('#permission_role_nomina_electronica_0').prop('disabled', false);
      $('#permission_role_nomina_electronica_0').prop('checked', true);
    }
    else if (value === '2' || value === '3') {
      $('#permission_role_nomina_electronica_0').prop('disabled', false);
      $('#permission_role_nomina_electronica_1').prop('disabled', false);
      $('#permission_role_nomina_electronica_2').prop('disabled', false);
      $('#permission_role_nomina_electronica_3').prop('disabled', false);
    }
  }

  /** Habilita/deshabilita los radio buttons de boletas honorarios */
  function changeFeeServicesRadios() {
    const value = $("input[name='permission_role[fee_services]']:checked").val();
    if (value === '0') {
      $('#permission_role_boletas_honorario_2').prop('disabled', true);
      $('#permission_role_boletas_honorario_1').prop('disabled', true);
      $('#permission_role_boletas_honorario_0').prop('checked', true);
    }
    else {
      $('#permission_role_boletas_honorario_1').prop('disabled', false);
      $('#permission_role_boletas_honorario_2').prop('disabled', false);
    }
  }

  /** Habilita/deshabilita los radio buttons de información personal */
  function changeEmployeePermissionsRadios() {
    const value = $("input[name='permission_role[personal_information]']:checked").val();
    if (value === '0') {
      $('#permission_role_jobs_3').prop('disabled', true);
      $('#permission_role_jobs_2').prop('disabled', true);
      $('#permission_role_jobs_1').prop('disabled', true);
      $('#permission_role_jobs_0').prop('disabled', false);
      $('#permission_role_jobs_0').prop('checked', true);

      $('#permission_role_prevision_2').prop('disabled', true);
      $('#permission_role_prevision_1').prop('disabled', true);
      $('#permission_role_prevision_0').prop('disabled', false);
      $('#permission_role_prevision_0').prop('checked', true);
    }
    else {
      $('#permission_role_jobs_0').prop('disabled', false);
      $('#permission_role_jobs_1').prop('disabled', false);
      $('#permission_role_jobs_2').prop('disabled', false);
      $('#permission_role_jobs_3').prop('disabled', false);

      $('#permission_role_prevision_2').prop('disabled', false);
      $('#permission_role_prevision_1').prop('disabled', false);
      $('#permission_role_prevision_0').prop('disabled', false);

    }
  }

  /** Habilita/deshabilita los radio buttons de procesos */
  function changeProcessPermissionsRadios() {
    const value = $("input[name='permission_role[processes]']:checked").val();
    if (value === '0') {
      $('#permission_role_download_banktransfer_0').prop('disabled', true);
      $('#permission_role_download_banktransfer_0').prop('checked', true);

      $('#permission_role_download_banktransfer_1').prop('disabled', true);
      $('#permission_role_download_banktransfer_1').prop('checked', false);

      $('#permission_role_send_banktransfer_0').prop('disabled', true);
      $('#permission_role_send_banktransfer_0').prop('checked', true);

      $('#permission_role_send_banktransfer_1').prop('disabled', true);
      $('#permission_role_send_banktransfer_1').prop('checked', false);

      changeRestrictedSettlementChangesRadios();
    }
    else {
      $('#permission_role_download_banktransfer_0').prop('disabled', false);
      $('#permission_role_download_banktransfer_1').prop('disabled', false);
      $('#permission_role_send_banktransfer_0').prop('disabled', false);
      $('#permission_role_send_banktransfer_1').prop('disabled', false);

      $('#permission_role_restricted_settlement_changes_0').prop('disabled', true);
      $('#permission_role_restricted_settlement_changes_1').prop('disabled', true);
      $('#permission_role_restricted_settlement_changes_1').prop('checked', true);
    }
  }

  /** Habilita/deshabilita los radio buttons de cambios en liquidaciones restringidas */
  function changeRestrictedSettlementChangesRadios() {
    const noModificaProcesos = $('#permission_role_processes_0').is(':checked');
    if (noModificaProcesos) {
      const modificaLiquidaciones = ['#permission_role_asistencia_3', '#permission_role_assign_items_3'];
      const disabled = !modificaLiquidaciones.some((id) => $(id).is(':checked'));
      $('#permission_role_restricted_settlement_changes_0').prop('disabled', disabled);
      $('#permission_role_restricted_settlement_changes_1').prop('disabled', disabled);
      if (disabled) {
        $('#permission_role_restricted_settlement_changes_0').prop('checked', true);
      }
    }
  }
});
