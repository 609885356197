/** @file
 * comportamientos js para EmployeeGoals
 */
import onmount from 'onmount';

onmount('#add-intermediate', function () {
  this.addEventListener('click', function (event) {
    event.preventDefault();

    const intermediateFields = document.getElementById('intermediate-fields');

    const fieldSet = document.createElement('div');
    fieldSet.className = 'row';

    const colPercentage = document.createElement('div');
    colPercentage.className = 'col-md-4';
    colPercentage.innerHTML = `
      <div class="form-group">
        <div class="input-group input-append">
          <input class="form-control string optional"
            placeholder="0" type="decimal" name="talent_goal_scale[intermediate_percentages][]">
          <div class="input-group-append">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </div>
    `;

    const colUnits = document.createElement('div');
    colUnits.className = 'col-md-3';
    colUnits.innerHTML = `
      <div class="form-group">
        <input class="form-control string optional"
          placeholder="0" type="decimal" name="talent_goal_scale[intermediate_units][]">
      </div>
    `;

    const colButton = document.createElement('div');
    colButton.className = 'col-md-1 align-self-end';

    const formGroup = document.createElement('div');
    formGroup.className = 'form-group float-right';

    const removeButton = document.createElement('button');
    removeButton.type = 'button';
    removeButton.className = 'btn btn-icon btn-destructive btn-md ghost text-danger-400 remove-field cursor-pointer';
    removeButton.innerHTML = `<i class="icon fas fa-trash" aria-hidden="true"></i>`;

    removeButton.addEventListener('click', function () {
      fieldSet.remove();
    });

    formGroup.appendChild(removeButton);
    colButton.appendChild(formGroup);

    fieldSet.appendChild(colPercentage);
    fieldSet.appendChild(colUnits);
    fieldSet.appendChild(colButton);

    intermediateFields.appendChild(fieldSet);
  });
});

onmount('.remove-field', function () {
  this.addEventListener('click', function () {
    this.closest('.row').remove();
  });
});
