/** @file
 * Archivo de marcas de amplitud para Marketplace
 */
import onmount from 'onmount';
import * as notify from '../../../../../../../../app/assets/javascripts/lib/buk/notice';

onmount('.sidebar-wrapper', () => {
  const solutionMenuAccess = $('#solutions-buk');
  const integrationMenuAccess = $('#integrations-buk');

  solutionMenuAccess.on('click', () => {
    doMarkAmplitude(solutionMenuAccess, 'Open_Marketplace', marketplaceAccessDataSet());
  });

  integrationMenuAccess.on('click', () => {
    doMarkAmplitude(integrationMenuAccess, 'Open_Integrations', marketplaceAccessDataSet());
  });
});

// Marcas Amplitude de vista Marketplace
onmount('#marketplace-integrations-section', () => {
  const configIntegrationCards = $('.integration-config-card');
  const requestIntegrationCards = $('.integration-request-card');

  // Marcación de evento para ingresar a configuración de una integración
  configIntegrationCards.each(function (_, integrationCard) {
    $(this).on('click', () => {
      const integrationData = getIntegrationDataset(integrationCard);
      doMarkAmplitude($(this), 'configurate_marketplace_integration_click', integrationData);
    });
  });

  // Marcación de evento primer paso para solicitar una integración - Abrir modal
  requestIntegrationCards.each(function (_, integrationCard) {
    $(this).on('click', () => {
      const integrationData = getIntegrationDataset(integrationCard);
      doMarkAmplitude($(this), 'request_marketplace_integration_click', integrationData);
    });
  });
});

// Marcación de evento segundo paso solicitar una integración - Contactar por mail
onmount('#contact-integration', () => {
  const integrationContact = $('#contact-integration');
  const integrationContactData = getIntegrationData(integrationContact);

  integrationContact.on('click', () => {
    doMarkAmplitude(integrationContact, 'contact_me_marketplace_integration_click', integrationContactData);
  });
});

// Marcas Amplitude de configuración exitosa en Integraciones usando AJAX
// El uso de AJAX request se debe a que ciertas integraciones como Trabajando.com, Backup Banks y Nómina Electrónica
// al momento de realizar su configuración poseen validaciones en sus formularios, entonces esto nos condiciona a no realizar
// una marcación erronéa al momento de fallar una validación y solo realizarla al momento que la configuración sea exitosa.

// Nombre del evento cuando se configura una integración exitosamente
const configurationSuccessEvent = 'configuration_marketplace_integration_success';

// Integración Trabajando.com - marcación usando AJAX
onmount('#trabajando_integration', () => {
  const trabajandoIntegrationForms = $('.trabajando-config-integration');

  trabajandoIntegrationForms.each(function (_, integrationForm) {
    $(this).on('ajax:beforeSend', function (event) {
      event.preventDefault();
      // Deshabilitar boton submit
      const btn = $(this).find("input[type='submit']");
      btn.val('Guardando...');
      btn.prop('disabled', true);
      // Validacion de formulario
      const form = $(this)[0];
      const url = $(this).attr('action');
      const dataForm = Object.fromEntries(new FormData(form).entries());
      const prefix = 'integration_seleccion_trabajando_config';
      const data = {
        client: checkParam(dataForm[`${prefix}[client]`]),
        domain_id: checkParam(dataForm[`${prefix}[domain_id]`]),
        token: checkParam(dataForm[`${prefix}[token]`]),
        company_id: checkParam(dataForm[`${prefix}[company_id]`]),
        enabled: checkParam(dataForm[`${prefix}[enabled]`]),
        empresa_id: checkParam(dataForm[`${prefix}[empresa_id]`]),
        company_activity_id: checkParam(dataForm[`${prefix}[company_activity_id]`]),
      };
      const errorMessage = `Error al configurar integración de empresa ${integrationForm.dataset.empresa}`;

      if (integrationForm.dataset.id) {
        patchWithError(url, prefix, data, integrationForm, configurationSuccessEvent, errorMessage, btn);
      }
      else {
        postWithError(url, prefix, data, integrationForm, configurationSuccessEvent, errorMessage, btn);
      }

    });
  });
});

// Integración Envio archivos de banco - Backup Banks - marcación usando AJAX
onmount('#backupbanks_integration', () => {
  const backupbanksIntegrationForms = $('.backupbanks-config-integration');

  backupbanksIntegrationForms.each(function (_, integrationForm) {
    $(this).on('ajax:beforeSend', function (event) {
      event.preventDefault();
      // Deshabilitar boton submit
      const btn = $(this).find("input[type='submit']");
      btn.val('Guardando...');
      btn.prop('disabled', true);
      // Validacion de formulario
      const form = $(this)[0];
      const dataForm = Object.fromEntries(new FormData(form).entries());
      const prefix = 'integrations_backup_bank';
      const data = {
        url: checkParam(dataForm[`${prefix}[url]`]),
        password: dataForm[`${prefix}[password]`],
        path: dataForm[`${prefix}[path]`],
        encryption_active: dataForm[`${prefix}[encryption_active]`],
        public_key: dataForm[`${prefix}[public_key]`],
        private_key: dataForm[`${prefix}[private_key]`],
        passphrase: dataForm[`${prefix}[passphrase]`],
        recipient: dataForm[`${prefix}[recipient]`],
        private_recipient: dataForm[`${prefix}[private_recipient]`],
        empresa_id: checkParam(dataForm[`${prefix}[empresa_id]`]),
      };

      if (integrationForm.dataset.id) {
        patch($(this).attr('action'), prefix, data, integrationForm, configurationSuccessEvent);
      }
      else {
        post($(this).attr('action'), prefix, data, integrationForm, configurationSuccessEvent);
      }
    });

  });
});

function toggleReportCausations(check, block) {
  $(check).on('change', function (event) {
    const isCheckboxChecked = event.target.checked;
    $(block).prop('disabled', isCheckboxChecked);
  }).trigger('change');
}

// Integración Nómina Electrónica - Electronic Payroll - marcación usando AJAX
onmount('#nomina_electronica_integration', () => {
  const nominaElectronicaIntegrationForms = $('.nomina_electronica-config-integration');

  nominaElectronicaIntegrationForms.each(function (_, integrationForm) {
    const id = integrationForm.id.replace('electronic_payroll_form_', '');
    toggleReportCausations(`#report_causations_${id}`, `#report_causation_vacation_${id}`);
    toggleReportCausations(`#report_causation_vacation_${id}`, `#report_causations_${id}`);
    $(this).on('ajax:beforeSend', function (event) {
      event.preventDefault();
      // Deshabilitar boton submit
      const btn = $(this).find("input[type='submit']");
      btn.val('Guardando...');
      btn.prop('disabled', true);
      // Validacion de formulario
      const form = $(this)[0];
      const dataForm = Object.fromEntries(new FormData(form).entries());
      const prefix = 'integrations_electronic_payroll';
      const checkParamCustomLogin = (value) => {
        const isCustomLogin = Number(dataForm[`${prefix}[custom_login]`]);
        return isCustomLogin ? checkParam(value) : value;
      };
      const data = {
        company_id_qa: dataForm[`${prefix}[company_id_qa]`],
        company_id: dataForm[`${prefix}[company_id]`],
        test_set_id: dataForm[`${prefix}[test_set_id]`],
        active: dataForm[`${prefix}[active]`],
        use_test_set_id: dataForm[`${prefix}[use_test_set_id]`],
        custom_login: dataForm[`${prefix}[custom_login]`],
        custom_virtual_operator: checkParamCustomLogin(dataForm[`${prefix}[custom_virtual_operator]`]),
        custom_user: checkParamCustomLogin(dataForm[`${prefix}[custom_user]`]),
        custom_password: checkParamCustomLogin(dataForm[`${prefix}[custom_password]`]),
        notify: dataForm[`${prefix}[notify]`],
        report_causations: dataForm[`${prefix}[report_causations]`],
        report_causation_vacation: dataForm[`${prefix}[report_causation_vacation]`],
        empresa_id: dataForm[`${prefix}[empresa_id]`],
      };

      if (integrationForm.dataset.id) {
        patch($(this).attr('action'), prefix, data, integrationForm, configurationSuccessEvent);
      }
      else {
        post($(this).attr('action'), prefix, data, integrationForm, configurationSuccessEvent);
      }
    });

  });
});

// Marcas Amplitude de configuración exitosa en Integraciones
// Integraciones EBMetrics, Buk - Capacitaciones, Single Sign On - Configuración de un solo formulario SIN VALIDACIONES
// Integraciones Imed, BNE y Geovictoria 2 - Configuración de varios formularios por empresa SIN VALIDACIONES

// La clase 'config-integration-container' representa al contenedor de la vista de configuración usualmente son outer o inner box
// La variable singleButtonSubmit buscará por elementos(usualmente submit buttons) que tenga un id que termine en '-config-submit'
// es recomendable usar este id cuando la configuración solo se base en un formulario para toda la integración como EBMetrics por ejemplo.

// La variable severalButtonSubmit buscará por elementos(usualmente submit buttons) que tengan una clase que termine en '-config-submit'
// es recomendable usar esta clase cuando la configuración se separa por empresa(la vista tendra varios formularios), entonces a esto se
// debe el uso de una clase para que represente a varios submit de cada formulario. Por ejemplo Imed.
onmount('.config-integration-container', () => {
  const singleButtonSubmit = $("[id$='-config-submit']");
  const severalButtonSubmit = $("[class$='-config-submit']");
  if (singleButtonSubmit.length > 0) {
    const integrationData = getIntegrationData(singleButtonSubmit);
    singleButtonSubmit.on('click', () => {
      doMarkAmplitude(singleButtonSubmit, configurationSuccessEvent, integrationData);
    });
  }
  else if (severalButtonSubmit.length > 0) {
    severalButtonSubmit.each(function (_, integrationButton) {
      $(this).on('click', () => {
        const integrationData = getIntegrationDataset(integrationButton);
        doMarkAmplitude($(this), configurationSuccessEvent, integrationData);
      });
    });
  }
});

function doRequest(url, method, key, data) {
  const newData = {};
  newData[`${key}`] = data;
  return (
    $.ajax({
      url: url,
      method: method,
      data: newData,
    })
  );
}

function checkParam(value) {
  return value || null;
}

function invalidRequest(data) {
  return Object.values(data).includes(null);
}

function getIntegrationDataset(element) {
  return ({
    'integration_name': element.dataset.name,
    'integration_type': element.dataset.tag,
  });
}

function getIntegrationData(element) {
  return ({
    'integration_name': element.data('name'),
    'integration_type': element.data('tag'),
  });
}

function patch(url, prefix, data, element, amplitudeAction) {
  doRequest(url, 'patch', prefix, data).done(function () {
    if (!invalidRequest(data)) {
      const integrationData = getIntegrationDataset(element);
      doMarkAmplitude($('html'), amplitudeAction, integrationData);
    }
  });
}

function post(url, prefix, data, element, amplitudeAction) {
  doRequest(url, 'post', prefix, data).done(function () {
    if (!invalidRequest(data)) {
      const integrationData = getIntegrationDataset(element);
      doMarkAmplitude($('html'), amplitudeAction, integrationData);
    }
  });
}

function patchWithError(url, prefix, data, element, amplitudeAction, errorMessage, disabledElement) {
  doRequest(url, 'patch', prefix, data).done(function () {
    if (invalidRequest(data)) {
      notify.clear();
      notify.error(errorMessage);
      disabledElement.val('Guardar');
      disabledElement.prop('disabled', false);
    }
    else {
      const integrationData = getIntegrationDataset(element);
      doMarkAmplitude($('html'), amplitudeAction, integrationData);
    }
  });
}

function postWithError(url, prefix, data, element, amplitudeAction, errorMessage, disabledElement) {
  doRequest(url, 'post', prefix, data).done(function () {
    if (invalidRequest(data)) {
      notify.clear();
      notify.error(errorMessage);
      disabledElement.val('Guardar');
      disabledElement.prop('disabled', false);
    }
    else {
      const integrationData = getIntegrationDataset(element);
      doMarkAmplitude($('html'), amplitudeAction, integrationData);
    }
  });
}

function doMarkAmplitude(element, message, data) {
  element.trigger('amplitudeTracking',
    {
      message: message,
      data: data,
    });
}

function marketplaceAccessDataSet() {
  return ({
    source: 'Menú',
  });
}
