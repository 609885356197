/** @file TODO: documentar */

// Esto deberias invocarlo en algun metodo del archivo
import onmount from 'onmount';

onmount('[data-tooltip], [data-toggle="tooltip"]', function () {
  $(this).tooltip({ trigger: 'hover', boundary: 'window' });
});

/**
 * Permite añadir un tooltip a un div si es que tiene overflow
 * Modo de Uso:
 * usar data-tooltip-overflog='mi tooltip'
 * @example
 * <div class='clase_con_text_overflow' data-tooltip-overflog='mi tooltip'>
 *   texto muy largo...
 * </div>
 */
onmount('[data-tooltip-overflow]', function () {
  $(this).hover(() => {
    if (this.scrollHeight > this.clientHeight || this.scrollWidth > this.clientWidth) {
      const title = $(this).data('tooltip-overflow');
      $(this).attr('title', title);
    }
    else {
      $(this).removeAttr('title');
    }
  });
});

// Desactivar tooltips para elementos que no lo necesitan
const disableTooltip = (elements) => {
  elements.each(function () {
    const $this = $(this);
    $this.tooltip('dispose').removeAttr('data-tooltip').removeAttr('aria-describedby');
    const isActionDisabled = $this.hasClass('disabled');
    if (!isActionDisabled) {
      $this.removeAttr('title');
    }
  });
};

// Evita cerrar el dropdown al hacer click en elementos dentro de este y muestra tooltip en acciones deshabilitadas
const handleClickDropdownAction = (element) => {
  const allActionsElements = $('.table-actions').find('a');
  element.on('click', function (e) {
    if ($(this).closest('.dropdown').length > 0) e.stopPropagation();
    $(this).tooltip({
      trigger: 'click',
      boundary: 'window',
    });
  });
  // Desactivar tooltips al cerrar el dropdown mas cercano
  element.closest('.dropdown').on('hidden.bs.dropdown', function () {
    disableTooltip(allActionsElements);
  });
};

/// Permite desactivar los tooltips en mobile para el table actions excepto en los botones deshabilitados
onmount('.table-actions', function () {
  const tableActionsElements = $(this).find('a');
  const dropdownActions = $(this).find('.table-actions__dropdown');

  if ('ontouchstart' in window) {

    disableTooltip(dropdownActions);

    // Si accion es deshabilitada, se remueve atributo disabled para posteriormente mostrar tooltip
    tableActionsElements.each(function () {
      const $this = $(this);
      const isActionDisabled = $this.attr('disabled') === 'disabled';
      if (isActionDisabled) {
        $this.removeAttr('disabled');

        $this.on('click', function () {
          // permite cerrar el tooltip si ya está visible
          const tooltipVisible = $this.attr('aria-describedby') !== undefined;
          if (tooltipVisible) {
            disableTooltip($this);
            return;
          }
          // volver a inicializar el tooltip para que se muestre en una proxima acción
          $this.tooltip('dispose').tooltip({ trigger: 'click', boundary: 'window' }).tooltip('show');
          disableTooltip(tableActionsElements.not($this));
        });
      }
      else {
        // Se remueve el tooltip si el botón no está deshabilitado
        disableTooltip($this);
      }
    });
    handleClickDropdownAction(tableActionsElements);
  }
});

//refresca el tooltip, evitando que quede sticky en botones sin href
onmount('[data-tooltip], [href="#void"]', function () {
  const isTableAction = $(this).closest('.table-actions').length > 0;
  if (isTableAction) { return; }
  $(this).on('click', function () {
    $(this).tooltip('dispose').tooltip();
  });
});

// Ocultar tooltip al momento de cambiar de página evitando que turbolinks lo mantenga activo en el DOM al volver rapidamente
document.addEventListener('turbolinks:before-cache', () => {
  const isTooltipVisible = $('.tooltip').hasClass('show');
  if (isTooltipVisible) {
    $('.tooltip').tooltip('hide');
  }
});
