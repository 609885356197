/** @file TODO: documentar */
import onmount from 'onmount';

async function alert(title, message, type) {
  const { default: swal } = await import('sweetalert2/dist/sweetalert2.js');
  return await swal.fire(title, message, type);
}
export function warning(message, title = 'Advertencia') {
  return alert(title, message, 'warning');
}
export function error(message, title = 'Error') {
  return alert(title, message, 'error');
}
export function info(message, title = 'Información') {
  return alert(title, message, 'info');
}
export function success(message, title = 'Éxito') {
  return alert(title, message, 'success');
}

async function alertAction(title, message, confirmButtonText, cancelButtonText, icon, html, customConfirmButton,
  swalInput, swalInputAttributes) {
  const { default: swal } = await import('sweetalert2/dist/sweetalert2.js');
  let swalAttributes = {
    title: title ? title : message,
    text: title ? message : null,
    icon: icon,
    html: html,
    confirmButtonText: confirmButtonText,
    showCancelButton: cancelButtonText != null,
    cancelButtonText: cancelButtonText,
    showCloseButton: true,
    closeButtonHtml: '<span class="material-icons">close</span>',
    reverseButtons: true,
    keydownListenerCapture: true,
    customClass: {
      confirmButton: customConfirmButton ? customConfirmButton : '',
      popup: swalInput === 'true' && swalInputAttributes.inputValidator ? 'input-swal2-popup' : '',
    },
  };
  if (swalInput === 'true' && swalInputAttributes.inputValidator) {
    swalAttributes = Object.assign(swalAttributes, swalInputAttributes);
  }
  return await swal.fire(swalAttributes);
}

/** Muestra un alert con solo un mensaje en forma de titulo. */
export function action(message = null, confirmButtonText = 'Aceptar', cancelButtonText = null, icon = null, html = null,
  customConfirmButton = null, swalInput = false, swalInputAttributes = {}) {
  return alertAction(message, null, confirmButtonText, cancelButtonText, icon, html, customConfirmButton, swalInput,
    swalInputAttributes);
}

/** El alert se muestra con titulo y mensaje separados */
export function actionMessage(title, message, confirmButtonText = 'Aceptar', cancelButtonText = null, icon = null,
  customConfirmButton = null, swalInput = false, swalInputAttributes = {}) {
  return alertAction(title, message, confirmButtonText, cancelButtonText, icon, null, customConfirmButton, swalInput,
    swalInputAttributes);
}

onmount('.content > .alert', function () {
  const alertToMove = $(this);
  const bodyOuterBox = $('.box-generals').find('.box-body.box-height-screen');
  if(bodyOuterBox.length > 0) {
    onmount(bodyOuterBox, function () {
      alertToMove.prependTo(this);
      alertToMove.removeClass('hide');
    });
  }
  setTimeout(() => {
    alertToMove.removeClass('hide');
  }, 100);
});
