/** @file TODO: documentar */
import onmount from 'onmount';

onmount('#input-prepend', function () {
  const prepends = document.querySelectorAll('#input-prepend');
  prepends.forEach(function (prependItem) {
    prependItem.parentElement.classList.add('input-prepend');
  });
});

onmount('#input-append', function () {
  const appends = document.querySelectorAll('#input-append');
  appends.forEach(function (appendItem) {
    appendItem.parentElement.classList.add('input-append');
  });
});

onmount('.input-group', function () {
  $('.input-group-append').on('click', function () {
    const $parent = $(this).parent();
    $parent.find('input')?.trigger('focus');
  });
});

// Deshabilitar el evento mousewheel en los input de tipo number cuando
// está en focus para prevenir que cambian el valor al hacer scrolling

// Se delegan los eventos focus al elemento form para evitar
// demasiados event-listeners, pudiendo impactar el performance
onmount('form', function () {
  $(this).on('focus wheel.disableScroll', 'input[type=number]', function (event) {
    event.preventDefault();
  });

  $(this).on('blur', 'input[type=number]', function () {
    $(this).off('wheel.disableScroll');
  });
});
