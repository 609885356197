/** @file TODO: documentar */
import { warning } from '../../../../../../../app/assets/javascripts/lib/buk/alert';
import ModalBuilder from '../../../../../../../app/assets/javascripts/lib/modal-builder';
import { toCode } from '../../../../../../../app/assets/javascripts/lib/string';
import onmount from 'onmount';

onmount('.available_benefits_form', function () {

  // actualiza las previsualizaciones de las imagenes de beneficio, banner y favorite
  $('.modal input[type="hidden"]').on('change', function () {
    const imgPreviewValue = $(this);
    const dataAttr = imgPreviewValue.data('img-preview');
    $(`img[data-target=${dataAttr}]`).each(function () {
      $(this).attr('src', imgPreviewValue.val());
    });
  });

  // modifica modal de croppie para permitir cortar imágenes mas grandes > 1200 px
  $('#upload-image-formulary-croppie_favorite').find('.modal-xl').css('max-width', 1350);
});

onmount('#filter-benefits', function () {
  var all = $(this).find('.show-all');
  var inProcess = $(this).find('.show-in-process');
  var approved = $(this).find('.show-approved');
  var rejected = $(this).find('.show-rejected');

  all.click(function () {
    $('#benefits').find('.benefits').fadeIn();
  });

  inProcess.click(function () {
    showOnly('.in_process');
  });

  approved.click(function () {
    showOnly('.approved');
  });

  rejected.click(function () {
    showOnly('.rejected');
  });

  function showOnly(name) {
    const benefit = $('#benefits').find('.benefits');
    if (benefit.has(name).length > 0) {
      benefit.fadeIn();
      benefit.not(":has(" + name + ")").fadeOut();
    }
  }
});

// Logica para el manejo de available version en formulario.
$(document).on('change', '#preaproved_benefit', function () {
  const versionsSelect = $('#versions_select');
  const buttonDelete = $('#button-delete-version');
  if (this.checked) {

    versionsSelect.val('version_1').trigger('change');

    versionsSelect.find("option:not([value='version_1'])").each(function () {
      const valueSelected = this.value;
      $(`#${valueSelected} .field-delete`).val('true');
      toggleButton(buttonDelete, 'true');
      toggleDisabledForm($(`#${valueSelected}`), 'true', buttonDelete);
    });
  }
  else {
    versionsSelect.find("option:not([value='version_1'])").each(function () {
      const valueSelected = this.value;
      $(`#${valueSelected} .field-delete`).val('false');
      toggleButton(buttonDelete, 'false');
      toggleDisabledForm($(`#${valueSelected}`), 'false', buttonDelete);
    });
  }
});


// Restaurar o eliminar atributo de versiones
$(document).on('click', '.button-delete-version-fields', function (e) {
  const buttonDelete = $(this);
  e.preventDefault();
  if (!buttonDelete.hasClass('disabled')) {
    const field = buttonDelete.siblings('input');
    const val = $(field).val() === "true" ? "false" : "true";
    $(field).val(val);
    toggleDisabledForm(buttonDelete.parents('tr'), val, buttonDelete);
    toggleButton(this, val);
  }
});

// Toogle boton de Eliminar/restaurar
function toggleButton(button, value) {
  const buttonToggle = $(button);
  if (value === "true") {
    buttonToggle.addClass("btn-outline-primary").removeClass("btn-outline-danger");
    buttonToggle.children('i').addClass('fa-trash-restore-alt').removeClass('fa-trash');
    buttonToggle.attr("data-original-title", "Revertir eliminar versión");
  }
  else {
    buttonToggle.addClass("btn-outline-danger").removeClass("btn-outline-primary");
    buttonToggle.children('i').addClass('fa-trash').removeClass('fa-trash-restore-alt');
    buttonToggle.attr("data-original-title", "Eliminar");
  }
}

// Toogle formulario al eliminar o restaurar.
function toggleDisabledForm(form, value, button) {
  const formToggle = $(form);
  formToggle.find('.button-delete-version-fields').each(function () { toggleButton(this, value); });
  if (value === "true") {
    formToggle.find('input, textarea, button, select, a, .button-delete-version-fields').attr('disabled', 'disabled');
    formToggle.find('button, a').addClass('disabled');
  }
  else {
    formToggle.find('input, textarea, button, select, a, .button-delete-version-fields').removeAttr('disabled');
    formToggle.find('button, a').removeClass('disabled');
  }
  formToggle.find('.field-delete').removeAttr('disabled');
  button.removeClass('disabled');
}

// Funciones para el manejo de versiones en edicion.
onmount('#versions_select', function () {
  const versionSelect = $(this);

  // Cambia el formulario de version que se muestra segun el Select
  $(document).on('change', '#versions_select', function () {
    const valueSelected = versionSelect.val();
    const buttonDelete = $('#button-delete-version');
    if (valueSelected == 'version_1') {
      buttonDelete.hide(500);
    }
    else {
      buttonDelete.show(500);
    }
    $(".version_form_wrapper").each(function () {
      const versionFormWrapper = $(this);
      if (valueSelected === this.id) {
        versionFormWrapper.show();
        toggleButton(buttonDelete, $(`#${valueSelected} .field-delete`).val());
      }
      else {
        versionFormWrapper.hide();
      }
    });
  });

  // Restaurar o eliminar version
  $(document).on('click', '#button-delete-version', function (e) {
    e.preventDefault();
    const valueSelected = versionSelect.val();
    const field = $(`#${valueSelected} .field-delete`);
    const val = $(field).val() === "true" ? "false" : "true";
    $(field).val(val);
    toggleButton(this, val);
    toggleDisabledForm($(`#${valueSelected}`), val, $(this));
  });
  // Incorporar al select de versiones nuevo formulario de version, actualizando sus indices.
  $(document).on('click', '#add-new-version', function () {
    const optionsVersions = ($('#versions_select option').length + 1).toString();

    $('#version_new-index').attr('id', `version_${optionsVersions}`);
    $('#version-panel-new-index').attr('id', `version-panel-${optionsVersions}`);
    $('#attributes-panel-new-index').attr('id', `attributes-panel-${optionsVersions}`);
    $('a[href="#version-panel-new-index"]').attr('href', `#version-panel-${optionsVersions}`);
    $('a[href="#attributes-panel-new-index"]').attr('href', `#attributes-panel-${optionsVersions}`);
    $('.fields-new-index').addClass(`fields-${optionsVersions}`).removeClass('fields-new-index');
    $('a[data-association-insertion-node=".fields-new-index"]').data('association-insertion-node', `.fields-${optionsVersions}`);
    let newId = $(`#version_${optionsVersions} label[for]`).first().attr("for").split("_");
    newId = newId[newId.length - 2];

    $('#integrate_assistance_new-index').attr('name', `available_benefit[available_versions_attributes][${newId}][integrate_assistance]`).attr('id', `integrate_assistance_${optionsVersions}`);
    $('#integrate_remunerations_new-index').attr('name', `available_benefit[available_versions_attributes][${newId}][integrate_remunerations]`).attr('id', `integrate_remunerations_${optionsVersions}`);

    versionSelect.append(new Option(`Versión ${optionsVersions}`, `version_${optionsVersions}`));
    versionSelect.val(`version_${optionsVersions}`);
    versionSelect.change();
  });
});

// Cambia campos y estructura del formulario que se muestra segun el tipo de beneficio
$(document).on('change', '[data-integration]', function () {
  const integrationType = $(this);
  const valueSelected = integrationType.val();
  const multipleVersion = integrationType.data('versions');
  const remunerationsWrapper = multipleVersion ? $(`[data-remunerations-wrapper='${integrationType.data('monetary')}']`)
    : $('[data-remunerations-wrapper]');
  const assistanceWrapper = multipleVersion ? $(`[data-assistance-wrapper='${integrationType.data('days')}']`)
    : $('[data-assistance-wrapper]');
  const requestDayInputDisabled = multipleVersion ? $(`[data-request-day-input-disabled='${integrationType.data('request-day-input')}']`)
    : $('[data-request-day-input-disabled]');
  if (valueSelected === 'monetary') {
    remunerationsWrapper.show(500);
    remunerationsWrapper.find(':input').attr('disabled', false);
    assistanceWrapper.hide();
    assistanceWrapper.find(':input').attr('disabled', true);
    requestDayInputDisabled.hide();
  }
  else if (valueSelected === 'days') {
    remunerationsWrapper.hide();
    remunerationsWrapper.find(':input').attr('disabled', true);
    assistanceWrapper.show(500);
    assistanceWrapper.find(':input').attr('disabled', false);
    if (assistanceWrapper.find('[data-select-day-assigment-type]').val() === 'request_day') {
      requestDayInputDisabled.show(500);
    }
  }
  else {
    remunerationsWrapper.hide();
    assistanceWrapper.hide();
    remunerationsWrapper.find(':input').attr('disabled', true);
    assistanceWrapper.find(':input').attr('disabled', true);
    requestDayInputDisabled.hide();
  }
});



onmount('[data-benefit-request]', async function () {
  const requestTab = $(this);
  const btnPreAprobar = requestTab.find('[resolution="pre_approved"]');
  const btnRechazar = requestTab.find('[resolution="rejected"]');
  const btnAprobar = requestTab.find('[resolution="approved"]');
  const btnActions = btnAprobar.parents('.dropdown').find('button.btn-dropdown');
  const btnDownloadAttachments = requestTab.find('[resolution="download-attachments"]');
  const message = btnAprobar.data('original-title');
  const href = btnDownloadAttachments.attr('href');
  btnActions.addClass('disabled');

  const table = requestTab.find('#datatable_benefits_requests');
  await table.waitDataTable();

  requestTab.on('click change', '.benefit-request-checkbox', function () {
    const totalChecked = table.find('input[type=checkbox]:checked:not([data-select-all])').length;
    const toApproveChecked = table.find('input[type=checkbox].approve:checked').length;
    const toPreApproveChecked = table.find('input[type=checkbox].pre_approve:checked').length;
    const toRejectChecked = table.find('input[type=checkbox].reject:checked').length;
    const toDownloadAttachments = table.find('input[type=checkbox].download-attachments:checked').length;
    if (totalChecked === 0) {
      btnActions.addClass('disabled');
    }
    else {
      btnActions.removeClass('disabled');
      if (totalChecked == toApproveChecked) {
        btnAprobar.removeClass('disabled');
        btnAprobar.removeAttr('data-original-title');
      }
      else {
        btnAprobar.addClass('disabled');
        btnAprobar.attr('data-original-title', message);
      }
      if (totalChecked == toPreApproveChecked) {
        btnPreAprobar.removeClass('disabled');
        btnPreAprobar.removeAttr('data-original-title');
      }
      else {
        btnPreAprobar.addClass('disabled');
        btnPreAprobar.attr('data-original-title', message);
      }
      if (totalChecked == toRejectChecked) {
        btnRechazar.removeClass('disabled');
        btnRechazar.removeAttr('data-original-title');
      }
      else {
        btnRechazar.addClass('disabled');
        btnRechazar.attr('data-original-title', message);
      }
      // se pueden descargar archivos si al menos un beneficio tiene archivos para descargar
      if (toDownloadAttachments > 0) {
        btnDownloadAttachments.removeClass('disabled');
        btnDownloadAttachments.attr('href', href);
        btnDownloadAttachments.attr('data-modal', true);
      }
      else {
        btnDownloadAttachments.addClass('disabled');
        btnDownloadAttachments.attr('href', 'javascript:;');
        btnDownloadAttachments.removeAttr('data-modal');
      }
    }
  });

  requestTab.on('click', '.massive-action:not(.disabled)', function (ev) {
    const resolution = $(this).attr('resolution');
    if (resolution === 'download-attachments') return;
    const ids = [];
    requestTab.find('.benefit-request-checkbox:checked').each(
      function () {
        const id = $(this).attr('data-id');
        if (id) ids.push(id);
      });

    if (ids.length === 0) {
      warning('Seleccione al menos una solicitud de beneficio');
      return;
    }
    var modalUrl = '/benefits/massive_resolve_modal';
    if (resolution === 'approved' || resolution === 'rejected' || resolution === 'pre_approved') {
      if(resolution === 'pre_approved') {
        modalUrl = '/benefit/benefits/massive_pre_approve';
      }
      var title = '';
      if (resolution === 'approved') {
        title = 'Aprobar beneficios';
      }
      else if (resolution === 'pre_approved') {
        title = 'Pre-Aprobar beneficios';
      }
      else {
        title = 'Rechazar beneficios';
      }

      var size = 'xl';
      var params = { benefit_ids: ids, resolution: resolution };

      var modalBuilder = new ModalBuilder();
      modalBuilder.setFooter('');
      modalBuilder.setSize(size).show().loadFromRemote(modalUrl, params, title, 'post');
      modalBuilder.getModal().on('modal:reload', () => modalBuilder.loadFromRemote(modalUrl, params, title));

      ev.preventDefault();
      return false;
    }
  });
});


$(document).on('change', '#available_version_item_id, #available_benefit_available_versions_attributes_0_item_id', function () {
  const $target = $('#item_amount_input');
  const uniqueAmountObj = document.getElementById(`item_unique_amount_${$(this).val()}`);

  /*
    Si el monto es unico, el state debe ser false para ocultar el input y viceversa.
    Si se elige la opción en blanco tambien se oculta
  */
  const state = !(uniqueAmountObj && uniqueAmountObj.value === 'true' || !uniqueAmountObj);
  state ? $target.show('slow') : $target.hide('slow');
  $target.find(':input').prop('disabled', !state);
  $target.trigger('form-toggle-enable', state);

});

$(document).on('click', '#benefit-signature #close_signature', function () {
  var value = $('#available_benefit_id').val();
  document.getElementById('solicitar').style.display = "none";
  document.getElementById('finish_button' + value).style.display = "";
});

onmount('.benefit_field_values_sign', function () {
  const href = this.href;
  const sep = (href.indexOf('?') == -1) ? '?' : '&';
  const form = $('.benefit_field_values_sign').parents('form:first');
  $(this).attr('href', href + sep + form.serialize());

  $("input, select").change(function () {
    $('.benefit_field_values_sign').attr('href', href + sep + form.serialize());
  });

  onmount('[id^="sign-form-"]', function () {
    let fileId = this.id;
    fileId = fileId.split("-").pop();
    $('#file_id').val(fileId);
    $('.benefit_field_values_sign').attr('href', href + sep + form.serialize());
  });

});

onmount('#solicitar-benefit-form', function () {
  const benefitForm = $(this);

  benefitForm.on('keyup keypress', function (e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      return false;
    }
  });

  onmount('#submit_benefit', function () {
    const buttonSubmit = $(this);
    if (buttonSubmit.data('btn-type') === 'submit') {
      buttonSubmit.on('click', function () {
        buttonSubmit.prop('disabled', true);
        buttonSubmit[0].form.submit();
      });
    }
  });
});

onmount('#benefit-signature-container', function () {
  onmount('.document_signature > .alert_success', function () {
    $(this).parent().find("[data-dismiss='modal']").prop('disabled', true).hide();
    $('#cancel_benefit').prop('disabled', true).hide();
    $('#submit_benefit').prop('disabled', false);
  });
});

$(document).on('change', '[data-item-selector]', function () {
  const targetName = $(this).prop('name').replace('item_id', 'item_formula');
  const target = $(`[name='${targetName}']`).closest('#item_amount_input');
  const uniqueAmountObj = document.getElementById(`item_unique_amount_${$(this).val()}`);

  const state = !(uniqueAmountObj && uniqueAmountObj.value === 'true' || !uniqueAmountObj);
  state ? target.show('slow') : target.hide('slow');
  target.prop('disabled', !state);
  target.trigger('form-toggle-enable', state);
});

/**
 * Genera la documentación para el template de variables de las formula de versiones de beneficios
 *
 * El modal de variables solo puede mostrar información persistida, este método inyecta
 * en el los atributos de las veriones que podrian haber cambiado o no estar
 * persistidos en la base de datos.
 */
onmount('[data-controller="benefit-template"]', function () {
  const table = $(this);
  const tableBody = $('<tbody></tbody>');
  const headTemplate = (benefitName) => `
    <thead>
        <tr>
          <th colspan="2">${benefitName}</th>
        </tr>
      </thead>
      `;
  const rowTemplate = (variableName, variableKey) => `
      <tr>
      <td>${variableName}</td>
      <td><b>${variableKey}</b></td>
      </tr>
      `;
  /**
* Los formularios de creación y edición son distintos,
* así que los selectores para los atributos tambien lo son
*/
  let attributesSelector;
  let benefitVersionName;
  if (window.location.pathname.endsWith('/edit')) {
    attributesSelector = ".version_form_wrapper:visible [name*='available_version_fields_attributes'][name$='[name]']";
    benefitVersionName = $(".version_form_wrapper:visible [name*='available_versions_attributes'][name$='[name]']").val();
  }
  else {
    attributesSelector = "[name*='available_version_fields_attributes'][name$='[name]']";
    //En la creación de un beneficio, el nombre del beneficio se copia a la versión.
    benefitVersionName = $("[name='available_benefit[name]']").val();
  }

  const variables = $(attributesSelector).map((_i, input) => input.value);
  table.append(headTemplate(benefitVersionName));


  variables.each(function (_i, variable) {
    const row = rowTemplate(variable, `beneficio.${toCode(benefitVersionName)}.${toCode(variable)}`);
    tableBody.append(row);
  });

  table.append(tableBody);
});

$(document).on('change', '[data-select-day-assigment-type]', function () {
  const dayAssigmentType = $(this);
  const multipleVersion = dayAssigmentType.data('versions');
  const requestDayWrapper = multipleVersion ? $(`[data-assignment-request-day-wrapper='${dayAssigmentType.data('request-day')}']`)
    : $('[data-assignment-request-day-wrapper]');
  const adminDayWrapper = multipleVersion ? $(`[data-assignment-admin-day-wrapper='${dayAssigmentType.data('admin-day')}']`)
    : $('[data-assignment-admin-day-wrapper]');
  const requestDayInputDisabled = multipleVersion ? $(`[data-request-day-input-disabled='${dayAssigmentType.data('request-day-input')}']`)
    : $('[data-request-day-input-disabled]');

  const requestInputs = requestDayWrapper.find('input');
  const adminInputs = adminDayWrapper.find('input');
  const valueSelected = dayAssigmentType.val();
  if (valueSelected === 'admin_day') {
    adminDayWrapper.show(500);
    requestDayWrapper.hide();
    requestDayInputDisabled.hide();
    requestInputs.attr('disabled', true);
    adminInputs.attr('disabled', false);
  }
  else if (valueSelected === 'request_day') {
    adminDayWrapper.hide();
    requestDayWrapper.show(500);
    requestDayInputDisabled.show(500);
    requestInputs.attr('disabled', false);
    adminInputs.attr('disabled', true);
  }
  else {
    requestDayInputDisabled.hide();
    adminDayWrapper.hide();
    requestDayWrapper.hide();
    requestInputs.attr('disabled', true);
    adminInputs.attr('disabled', true);
  }
});

/**
 * Al momento de agregar atributos a un beneficio, si el tipo de valor corresponde a 'carga'
 * se marca de forma automática el checkbox de 'Obligatorio' y en caso de cambiar el tipo de
 * valor, se desmarca.
 */
onmount('[data-check-checkbox]', function () {
  const $self = $(this);
  const $checkboxToCheck = $($self.data('check-checkbox').target);
  const $optionToEvaluate = $($self.data('check-checkbox').from);

  $self.on('change', function () {
    if ($optionToEvaluate.val() === 'carga') {
      $checkboxToCheck.prop('checked', true);
    }
    else {
      $checkboxToCheck.prop('checked', false);
    }
  });
});

/**
 * Este 'onmount' se encarga de prevenir que se desmarque el checkbox de 'Obligatorio' en el
 * caso de que el atributo sea de tipo carga.
 */
onmount('[data-prevent-uncheck]', function () {
  const $self = $(this);
  const $checkboxToPrevent = $($self.data('prevent-uncheck').target);
  const $optionToEvaluate = $($self.data('prevent-uncheck').from);

  $self.on('change', function () {
    if ($optionToEvaluate.val() === 'carga' && $checkboxToPrevent.prop('checked') === false) {
      $checkboxToPrevent.prop('checked', true);
    }
  });
});

/**
 * Este 'onmount' se encarga de ocultar el input de 'max_requests_per_member', si es que se
 * selecciona otro tipo de atributo diferente al de cargas.
 */
onmount('[data-toggle-input]', function () {
  const $self = $(this);
  const $inputToDisable = $($self.data('toggle-input').target);
  const $optionToEvaluate = $($self.data('toggle-input').from);

  $self.on('change', function () {
    if ($optionToEvaluate.val() !== 'carga' && $inputToDisable.is(":visible")) {
      $inputToDisable.prop('disabled', true);
      $inputToDisable.parent().parent().toggle('slow');
    }
  });
});

/** Se encarga de generar inputs dinamicamente para la administración de categorías */
onmount('#admin_categories_form', async function () {
  const { EmojiButton } = await import('@joeattardi/emoji-button');
  const categoriesForm = $(this);

  // Oculta el label de cada input y quita el margin bottom de cada form-group
  categoriesForm.find('label').remove();
  categoriesForm.find('.form-group').css('margin-bottom', 0);

  // permite remover emoji seleccionado en picker
  const removePlugin = {
    render(picker) {
      const button = document.createElement('button');
      button.innerHTML = 'Quitar Emoji';
      button.classList.add('btn', 'btn-danger');
      button.addEventListener('click', () => {
        $('.active-emoji-selector').html('😊');
        $('.active-emoji-selector').siblings('.selected_emoji').val('');
        $('.active-emoji-selector').toggleClass('active-emoji-selector');
        picker.hidePicker();
      });

      return button;
    },
  };

  // define picker para seleccionar emoji
  const picker = new EmojiButton({
    position: {
      top: '0',
      right: '0',
    },
    plugins: [removePlugin],
  });

  // Añade opción de eliminar las categorías existentes
  $('#admin-categories-inputs').children().each(function () {
    const categoryInput = $(this);
    $(this).find('.destroy-category').on('click', function (event) {
      event.preventDefault();
      categoryInput.addClass('hidden').find('.destroy_benefit_category').val(true);
    });
  });

  // Se clona un input para renderizarlo al presionar el botón 'Agregar categoría'
  const addCategoryButton = $('#add-new-category-button');
  const defaultInput = categoriesForm.find('#default-category-input');
  addCategoryButton.on('click', function (event) {
    event.preventDefault();
    const clone = defaultInput.children().clone();
    clone.find('.destroy-category').on('click', function (_event) {
      _event.preventDefault();
      clone.remove();
    });
    $('#all-categories').prepend(clone);
    clone.find('.emoji-trigger').on('click', function () {
      picker.togglePicker($(this));
      $(this).toggleClass('active-emoji-selector');
    });
  });
  defaultInput.remove();

  picker.on('emoji', selection => {
    const twemoji = document.createElement('span');
    twemoji.innerHTML = selection.emoji;
    twemoji.className = 'twemoji';
    $('.active-emoji-selector').html(twemoji);
    $('.active-emoji-selector').siblings('.selected_emoji').val(selection.emoji);
    $('.active-emoji-selector').toggleClass('active-emoji-selector');
  });

  picker.on('hidden', () => {
    $('.active-emoji-selector').removeClass('active-emoji-selector');
  });

  function monitorEmojiButton() {
    const emojiButtons = $('.emoji-trigger');

    for (var i = 0; i < emojiButtons.length; i++) {
      $(emojiButtons[i]).on('click', function () {
        picker.togglePicker($(this));
        $(this).toggleClass('active-emoji-selector');
      });
    }
  }

  monitorEmojiButton();
  // Permite que el picker pueda verse sobre el sidebar de benefits categories
  $('.emoji-picker__wrapper').css('z-index', '10000');

});

// Utilizado para poder desplazarse horizontalmente en las categorías de beneficios
// dentro de la vista Mis beneficios
onmount('#available-benefit-categories-bar', function () {
  const scrollDuration = 300;
  const leftArrow = $(this).find('#available-benefit-left-arrow');
  const rightArrow = $(this).find('#available-benefit-right-arrow');
  const categoriesSection = $(this).find('#available-benefit-categories');
  const itemsLength = $(this).find('.available-benefit-category').length;
  const itemSize = $(this).find('.available-benefit-category').outerWidth(true);

  // Corresponde al ancho disponible para mostrar los items
  const getCategoriesWrapperSize = () => categoriesSection.outerWidth();
  let categoriesWrapperSize = getCategoriesWrapperSize();
  // Para que sea responsivo
  $(window).on('resize', () => {
    categoriesWrapperSize = getCategoriesWrapperSize();
  });
  // Ancho total a utilizar para todos los items
  const getTotalItemsSize = () => itemsLength * itemSize;
  const totalItemsSize = getTotalItemsSize();
  // Porción que es invisible
  const getInvisibleSize = () => totalItemsSize - categoriesWrapperSize;
  let invisibleSize = getInvisibleSize();
  // Oculta/Muestra las flechas de scroll dependiendo del tamaño disponible
  const showAndHideScroll = () => {
    if (invisibleSize > 0) {
      categoriesSection.css('justify-content', 'start');
      categoriesSection.css('width', '90%');
      leftArrow.removeClass('hidden');
      rightArrow.removeClass('hidden');
    }
    else {
      categoriesSection.removeAttr('style');
      leftArrow.addClass('hidden');
      rightArrow.addClass('hidden');
    }
  };
  showAndHideScroll();
  // Hace que las flechas tengan un comportamiento responsivo
  $(window).on('resize', () => {
    invisibleSize = getInvisibleSize();
    showAndHideScroll();
  });

  // Scroll a la derecha
  $(rightArrow).on('click', () => {
    categoriesSection.animate({
      scrollLeft: `+=${itemSize}`,
    }, scrollDuration);
  });
  // Scroll a la izquierda
  $(leftArrow).on('click', () => {
    categoriesSection.animate({
      scrollLeft: `-=${itemSize}`,
    }, scrollDuration);
  });
});

// muestra spinner en btn
onmount('.zurich_btn', function () {
  $(this).on('click', (event)=>{
    const button = event.target;
    const icon = document.createElement('i');
    icon.classList.add('fas', 'fa-sync', 'right-icon', 'fa-fw', 'fa-spin', 'ml-2');
    icon.setAttribute('aria-hidden', true);
    button.appendChild(icon);
  });
});

// muestra iframe solo al terminar de cargarse
onmount('#zurich-iframe', function () {
  const loader = document.getElementById('iframe-loader');
  const sidebarBody = document.getElementsByClassName('aside-body')[0];
  this.onload = function () {
    sidebarBody.style.padding = 0;
    sidebarBody.style.overflowX = 'visible';
    loader.parentNode.removeChild(loader);
    this.removeAttribute('hidden');
  };
});

// Utilizado para bloquear el drag and drop en la administracion de beneficios
onmount('#admin_benefit_table', function () {
  // se obtienen todos los inputs de filtro de la tabla
  const filterInputs = $('[data-filter]');

  // se revisa si hay algun input de filtro con valor al renderizar la vista por primera vez
  const anyNotEmptyInput = filterInputs.toArray().some(input => input.value.trim() !== '');
  toggleRows(anyNotEmptyInput, true);

  // se levanta un evento de input en cada input de filtro
  $(document).on('input', '[data-filter]', handleFilterInputChange);

  // función que se encarga de manejar el evento de input en los filtros
  function handleFilterInputChange() {
    const anyNotEmpty = filterInputs.toArray().some(input => input.value.trim() !== '');
    toggleRows(anyNotEmpty, false);
  }
});

// Función que se encarga de manejar el cambio de estado de las filas de la tabla
function toggleRows(isDisabled, isInitRender) {
  const handlerColor = isDisabled ? 'silver' : '#212529';
  const cursorStyle = isDisabled ? 'auto' : 'move';
  const sortableClassArray = isDisabled
    ? ['sortable', 'sortable handler no-drag']
    : ['sortable handler no-drag', 'sortable'];

  $('.sortable').removeClass(sortableClassArray[0]).addClass(sortableClassArray[1]);

  if (isInitRender) {
    $('.material-icons:contains("drag_indicator")').css('color', handlerColor);
    $('.handler').css('cursor', cursorStyle);
    return;
  }
  $('#admin_benefit_table').on('draw.dt', function () {
    $('.material-icons:contains("drag_indicator")').css('color', handlerColor);
    $('.handler').css('cursor', cursorStyle);
  });
}
