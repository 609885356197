/** @file Controla las acciones que se dan en la cell del portal */
import load from '../../../../../../../../app/assets/javascripts/lib/buk/load';
import onmount, { $ } from 'onmount';
import Intercooler from '../../../../../../../../app/assets/javascripts/lib/intercooler.js';

onmount('#portal-adelantos-shorcut', function () {
  $(this).on('click', (e) => {
    $(e.target).trigger('amplitudeTracking', { message: 'adelantos_portal_shorcut_clicked', data: {} });
  });
});

// permite acticar el trigger de aquellos elementos que se encontraban invisible por clases de bootrap
// una vez que estos se vuelven visibles, se utiliza window.onresize porque apis mas precisas como
// ResizeObserver no son soportadas en Internet Explorer
onmount('.async-trigger', function () {
  const elementsToWatch = document.getElementsByClassName('async-trigger');
  const triggeredElements = [];

  // Set up the resize event listener
  window.onresize = () => {
    for (const element of elementsToWatch) {
      // Check if the element's size has changed
      if (element.offsetWidth !== 0 && !triggeredElements.includes(element)) {
        triggeredElements.push(element);
        const dataElement = $(element).find('div').find('[data-render-async]');
        load(dataElement[0], dataElement.attr('data-render-async'));
      }
    }
  };
});

// Cuenta la cantidad de publicaciones válidas seleccionadas.
function countEnabledMuralsChecked() {
  const checkedCheckboxes = 'input[type="checkbox"][class="mural-request-checkbox selection"]:not(:disabled):checked';
  const countCheckboxes = $('#mural_table').find(checkedCheckboxes).length;

  return countCheckboxes;
}
// Actualiza el número de publicaciones seleccionadas en el botón de descarga.
function updateButtonText() {
  const countChecked = countEnabledMuralsChecked();

  $('#download_area_report').toggleClass('disabled', countChecked === 0);
  $('#download_area_report').html('Descargar reporte por área (' + countChecked + ')');
}

// Selecciona o deselecciona todas las publicaciones válidas al seleccionar o deseleccionar el checkbox select all, respectivamente.
function handleEnabledCheckboxes(state) {
  const enabledCheckboxes = 'input[type="checkbox"][class="mural-request-checkbox selection"]:not(:disabled)';
  $('#mural_table').find(enabledCheckboxes).prop('checked', state);
}

function handleSelectAllCheckbox() {
  const enabledCheckboxes = 'input[type="checkbox"][class="mural-request-checkbox selection"]:not(:disabled)';
  const totalCheckboxes = $('#mural_table').find(enabledCheckboxes).length;
  const countChecked = countEnabledMuralsChecked();

  $('#select-all-murals').prop('checked', (totalCheckboxes === countChecked));
}

function resetCheckedCheckboxes() {
  handleEnabledCheckboxes(false);
  handleSelectAllCheckbox();
  updateButtonText();
}

onmount('#download_multiple_murals', function () {
  this.addEventListener('submit', function () {
    // Se utiliza setTimeout para asegurar que el código se ejecute después de la acción de envío.
    setTimeout(resetCheckedCheckboxes, 10);
  });
});

onmount('#mural_table', function () {
  $('.mural-request-checkbox').on('click', (_) => {
    updateButtonText();
    handleSelectAllCheckbox();
  });

  $('#select-all-murals').on('click', (_) => {
    const state = $('#select-all-murals').prop('checked');
    handleEnabledCheckboxes(state);
    updateButtonText();
  });
});

// Cierra el menú lateral antes de redireccionar al usuario a otra vista. De esta manera, la vista
// que se cachea para los turbolinks aparecerá con el menú lateral cerrado, para una experiencia
// más fluida.
onmount('.portal-comunicaciones', function () {
  $(document).on('turbolinks:before-cache', function () {
    $('body').removeClass('sidebar-open');
    $('.sidebar-background').remove();
    $('#tour-guiado').remove();
  });
});

// Se encarga de actualizar el widget de Tareas Pendientes del portal cuando ocurre un cambio
// desde el side bar de Tareas Pendientes.
onmount('#pending-aside', function () {
  var sidebar = document.querySelector('.aside-base');
  var sidebarContent = document.querySelector('#pending-aside');
  var pendingTaskListUpdated = false;

  var observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      // Detecta si hubo un cambio en la lista de Tareas Pendientes
      if ($(mutation.target).attr('id') === 'pending-aside') {
        pendingTaskListUpdated = true;
      }
      // Si se cierra el sidebar y hubo un cambio en la lista de Tareas Pendientes, actualiza
      // el widget de Tareas Pendientes del portal
      if ($(mutation.target).hasClass('aside-base') && pendingTaskListUpdated) {
        Intercooler.triggerRequest($('#pending-task-widget'));
        pendingTaskListUpdated = false;
      }
    });
  });

  observer.observe(sidebar, { childList: true });
  observer.observe(sidebarContent, { childList: true });
});
