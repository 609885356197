/**
 * @file amplitudeTracking
 * Componente para llamar a la API de Amplitude mediante interacciones de usuario
 *
 * Modo de uso:
 * - Definir un objeto a observar mediante data-amplitude-tracking
 * - Dentro de este amplitude-tracking se deben pasar 2 a 4 parámetros: message, data, url y event
 * - message siempre debería existir, mientras que data y url son complementarios y al menos debe existir uno
 * - event se debe agregar en caso de que se quiera capturar un evento diferente al click, en caso de no existir,
 * se tomará el click por defecto para el callback on
 * Se puede tener un objeto interactuable que tenga sweet-alert de por medio, la llamada a la API se gatillará
 * solo cuando se acepte el sweet-alert
 *
 * @params
 * [String] message: Es el nombre de la etiqueta de la métrica en Amplitude
 * [Hash] data: los datos que enviaremos hacia Amplitude para la métrica señalada
 * [String] url: Endpoint que puede entregar un JSON con información directa desde la base de datos.
 * [String] event: Evento a capturar del elemento que gatillará la llamada a amplitude.
 * [Boolean] multi-trigger (default: false): Atributo que indica si el evento se debe disparar 1 vez o N veces de acuerdo a la interacción del target (elemento HTML)
 *
 * @example
 * link_to('label',
            path(resource),
            method: :post,
            data: {
              'amplitude-tracking': {
                'message': 'tracking_name',
                'data': {
                  'source': source,
                }
              },
            },
 *
 * @example2
 * link_to('label',
            path(resource),
            method: :post,
            data: {
              'amplitude-tracking': {
                'message': 'tracking_name',
                'url': 'www.buk.cl/ruta_json'
              },
            },
  *
  * @example3
  * <%= select_tag :mes,
  *     options_for_select(periods, params[:mes] || variable.start_date),
  *     class: "form-control",
  *     data: {
          'amplitude-tracking': {
            'message': 'tracking_name',
            'data': {
              'source': source,
            },
            'event': 'change'
          },
        }, %>
 */

import onmount from 'onmount';

onmount('[data-amplitude-tracking]', async function () {
  const triggerObject = $(this);
  const amplitudeObject = triggerObject.data('amplitudeTracking');
  let triggered = false;

  const fetchDataFromRails = async (url) => {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  };

  const sendAmplitudeData = async () => {
    // Obtener datos adicionales desde Rails si existe una URL
    if (amplitudeObject['url']) {
      const additionalData = await fetchDataFromRails(amplitudeObject['url']);
      if (additionalData) {
        amplitudeObject['data'] = { ...amplitudeObject['data'], ...additionalData };
      }
    }

    const amplitudeData = { message: amplitudeObject['message'], data: amplitudeObject['data'] };

    // El sweet-alert hace un click forzado en confirm-box.js:21, por lo que se incluye un contador para solo gatillarlo en la primera llamada
    if (amplitudeObject['multi-trigger']) {
      triggerObject.trigger('amplitudeTracking', amplitudeData);
    }
    else if (!triggered) {
      triggerObject.trigger('amplitudeTracking', amplitudeData);
      triggered = true;
    }
  };

  const eventToCapture = amplitudeObject['event'] ?? 'click';
  triggerObject.on(eventToCapture, () => {
    // Si el trigger genera un sweet-alert, primero esperamos confirmación antes de enviar información
    if (triggerObject.data('confirm-swal')) {
      triggerObject.one('swal-confirmed', sendAmplitudeData);
    }
    else {
      sendAmplitudeData();
    }
  });
});
