/** @file
*
* Contiene lógica para manejar el mostrar/ocultar elementos en la UI con base a
* la interacción del scroll vertical en mobile
*/

import onmount from 'onmount';

onmount('.fiji-body', function () {
  if (window.innerWidth > 960) return;
  const wrapper = document.querySelector('.content-wrapper');

  wrapper.addEventListener('scroll', handleScroll);
});

/**
* @function handleScroll Detecta y responde al scroll vertical para mostrar/ocultar elementos en la interfaz de usuario
* @param {Array} elements Elementos de la interfaz de usuario a mostrar/ocultar
*/
function handleScroll() {
  const elements = [
    { selector: '.main-header', condition: false },
    { selector: '.box-title', condition: false },
    { selector: '.nav-tabs-custom', condition: false },
    { selector: '.fiji-actions-wrapper', condition: false },
  ];

  const scrollPosition = $(this).scrollTop();
  const scrollHeader = $(elements[0].selector).scrollTop();

  elements.forEach(item => {
    const element = document.querySelector(item.selector);
    if (element) {
      item.condition = scrollPosition > scrollHeader;
      element.classList.toggle('hide-scroll', item.condition);
    }
  });
}
