/** @file TODO: documentar */
import onmount from 'onmount';

async function initializeLightbox() {
  const photoswipeVideo = await import('photoswipe-video-plugin/dist/photoswipe-video-plugin.esm.min.js');
  const { default: PhotoSwipeLightbox } = await import('photoswipe/dist/umd/photoswipe-lightbox.umd.min.js');
  const { default: PhotoSwipeVideoPlugin } = photoswipeVideo;
  await import('photoswipe/dist/photoswipe.css');

  const lightbox = new PhotoSwipeLightbox({
    gallerySelector: '.gallery-media',
    childSelector: 'a',
    secondaryZoomLevel: (zoomLevelObject) => {
      const { elementSize: el, panAreaSize: pan } = zoomLevelObject;
      return el.x <= pan.x && el.y <= pan.y ? 1.4 : undefined;
    },
    wheelToZoom: true,
    maxZoomLevel: 2,
    pswpModule: () => import('photoswipe'),
  });

  let firstElWithBadge;
  let lastElWithBadge;

  lightbox.on('afterInit', () => {
    firstElWithBadge = lightbox.pswp.currSlide.data.element;
    hideBadge(firstElWithBadge);
  });

  lightbox.on('close', () => {
    lastElWithBadge = lightbox.pswp.currSlide.data.element;
    if (lastElWithBadge !== firstElWithBadge) {
      showBadge(firstElWithBadge);
      hideBadge(lastElWithBadge);
    }
  });

  lightbox.on('destroy', () => showBadge(lastElWithBadge));

  new PhotoSwipeVideoPlugin(lightbox, {
    autoplay: false,
  });
  lightbox.init();
}

function handleMediaLoad(item, width, height) {
  item.setAttribute('data-pswp-width', width);
  item.setAttribute('data-pswp-height', height);
}

function handleLoad(imgItem, media) {
  const isImage = media instanceof HTMLImageElement;
  handleMediaLoad(imgItem, isImage ? media.width : media.videoWidth, isImage ? media.height : media.videoHeight);
}

function hideBadge(el) {
  const remainder = el.querySelector('.remainder');
  remainder?.classList.add('remainder--hidden');
}

function showBadge(el) {
  const remainder = el.querySelector('.remainder');
  remainder?.classList.remove('remainder--hidden');
}

onmount('.gallery-media', async function () {
  const itemsImg = document.querySelectorAll('.pswp-gallery a');

  itemsImg.forEach((imgItem) => {
    const imageFormat = imgItem.getAttribute('href').match(/\.(jpg|jpeg|png)$/i);
    const media = imageFormat ? new Image() : document.createElement('video');
    media.src = imgItem.getAttribute('href');

    if (media instanceof HTMLImageElement) {
      media.onload = () => handleLoad(imgItem, media);
    }
    else {
      media.onloadedmetadata = () => handleLoad(imgItem, media);
      imgItem.setAttribute('data-pswp-type', 'video');
    }
  });

  initializeLightbox();
});
