/**
 * @file Muestra un "Ver más" "Ver menos" en un contenido con overflow.
 *  El link de "Ver mas" y "Ver menos" no aparecen si el contenido no tiene overflow.
 *  Oculta el excedente de contenido por defecto.
 *  Ejemplo (transpolar a estándares de erb):
 *  <div data-show-hide-more-content>
 *    <div>
 *      Contenido con overflow hidden.
 *    </div>
 *    <a class="toogle-more-link"></a>
 *  </div>
 *
 */
import onmount from 'onmount';

function checkContentVisibility(contentElement, txt) {
  const forceActivation = contentElement.data('force-collapse');

  // El maxHeigthCollapsed indica la altura máxima que debe tener el contenido cuando
  // está en estado colapsado
  let maxHeightCollapsed;
  const hasMaxHeightCollapsed = typeof contentElement.data('max-height-collapsed') === 'number';
  if (hasMaxHeightCollapsed) {
    maxHeightCollapsed = `${contentElement.data('max-height-collapsed')}px`;
  }

  if (hasMaxHeightCollapsed) {
    txt.css('maxHeight', maxHeightCollapsed);
  }

  const fullHeigth = txt.prop('scrollHeight');
  const currentHeigth = txt.height();

  // El fullHeigth dicta un tamaño superior mayor por redondeo.
  // Apartamos 2px para evitar cargar este componente para abrir 2px de contenido.
  const pxErrorRange = 2;

  // Verificamos si el contenido es mayor el ancho por defecto.
  if (fullHeigth > currentHeigth + pxErrorRange || forceActivation) {
    contentElement.addClass('content-collapse');

    contentElement.find('.toogle-more-link').on('click', function () {
      const toogleLink = $(this);
      const toogleParent = toogleLink.closest('[data-show-hide-more-content="true"]');
      const showMoreLabel = toogleParent.data('show-more-label');
      const showLessLabel = toogleParent.data('show-less-label');
      const toogleWrapper = toogleParent.find('.more-content-wrapper');
      const toogleMaxHeightCollapsed = `${toogleParent.data('max-height-collapsed')}px`;
      const toggleButtonLabel = toogleLink.find('.btn-label');
      const toogleExpanded = toogleParent.data('expanded');

      // Si expand == false, no se expandirá el contenido al presionar 'ver más'
      let expand = true;
      const hasExpand = typeof toogleParent.data('expand') === 'boolean';
      if (hasExpand) {
        expand = toogleParent.data('expand');
      }

      if (toogleExpanded === true) {
        toogleWrapper.css('minHeight', 'auto');
        toogleParent.removeClass('content-expanded');
        toogleParent.addClass('content-collapse');

        if (hasMaxHeightCollapsed) {
          toogleWrapper.css('maxHeight', toogleMaxHeightCollapsed);
        }

        if (toogleLink.text() === showLessLabel || toggleButtonLabel.text() === showLessLabel) {
          toogleLink.text(showMoreLabel);
        }

        toogleParent.data('expanded', false);
      }
      else {
        if (expand) {
          toogleParent.addClass('content-expanded');
          toogleParent.removeClass('content-collapse');

          if (hasMaxHeightCollapsed) {
            toogleWrapper.css('maxHeight', 'none');
          }

          if (toogleLink.text() === showMoreLabel || toggleButtonLabel.text() === showMoreLabel) {
            toogleLink.text(showLessLabel);
          }

          toogleParent.data('expanded', true);
        }
      }
    });
  }
}

function hideCollapsableContent(contentElement) {
  const txt = contentElement.find('.more-content-wrapper');
  txt.ready(function () { checkContentVisibility(contentElement, txt); });

  // Las imágenes por ckeditor no tienen alto en el dom,
  // su tamaño se establece cuando la imagen es mostrada en el HTML
  txt.find('img').on('load', function () { checkContentVisibility(contentElement, txt); });
}

onmount('[data-show-hide-more-content="true"]', function () {
  if (!$(this).hasClass('content-collapse')) {
    hideCollapsableContent($(this));
  }
});

$(document).on('hideContentEvent', function () {
  $('[data-show-hide-more-content="true"]').each(function () {
    if (!$(this).hasClass('content-collapse')) {
      hideCollapsableContent($(this));
    }
  });
});
