/** @file
 * Archivo de marcas de amplitud para reportes en Módulo de reportes, Administrativo, Talento y Procesos de Selección
 */
import onmount from 'onmount';

onmount('#button-update-report', async function () {
  $('#button-update-report').on('click', function () {
    const element = $(this);
    const importData = {
      'source': element.attr('data-report-source'),
      'submodulo': element.attr('data-submodule'),
    };
    sendAmplitudeTracking(element, 'Update_Report', importData);
  });
});

onmount('#open-report-talent', async function () {
  $('a[data-report_id="resumen"]').on('click', function () {
    const element = $(this);
    sendOpenReport(element, 'Open_Report');
  });
  $('a[data-report_id="avanzado"]').on('click', function () {
    const element = $(this);
    sendOpenReport(element, 'Open_Report');
  });
  $('#report-talent-resumen').ready(function () {
    const element = $('a[data-report_id="resumen"]');
    sendOpenReport(element, 'Open_Report');
  });
});

onmount('#selection_processes_list', async function () {
  $('a[data-class="tab-open-report"]').on('click', function () {
    const element = $(this);
    sendOpenReport(element, 'Open_Report');
  });
});

function sendOpenReport(element, event) {
  const importData = {
    'source': element.attr('data-report_source'),
    'report_name': element.attr('data-report_name'),
  };
  sendAmplitudeTracking(element, event, importData);
}

function sendAmplitudeTracking(element, message, data) {
  element.trigger('amplitudeTracking', {
    message: message,
    data: data,
  });
}
