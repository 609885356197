/**
 * @file Método que levanta el chat de Servicio al colaborador integrado con Intercom;
*/

import onmount from 'onmount';

let messengerClosed = true;
let firstEventFired = false;

// ********** Funciones de la nativas de Intercom **********

// Función para configurar ajustes de Intercom
function setIntercomSettings() {

  const employeeService = document.getElementById('communication_employee_service');
  let employeeServiceData;
  $.ajax({
    method: 'GET',
    dataType: 'json',
    async: false,
    url: employeeService.dataset.url,
    success: (data) => {
      employeeServiceData = JSON.parse(data);
    },
  });

  window.intercomSettings = {
    app_id: employeeServiceData.workspace_id,
    email: employeeServiceData.email,
    name: employeeServiceData.name,
    custom_launcher_selector: '#communication_employee_service',
  };
}

// Función para adjuntar el activador de Intercom
function attachIntercomActivator() {
  window.Intercom('reattach_activator');
  window.Intercom('update', window.intercomSettings);
}

// Función para crear una instancia de Intercom
function createIntercomInstance() {
  const intercomWrapper = function () {
    intercomWrapper.enqueue(arguments);
  };

  intercomWrapper.queue = [];
  intercomWrapper.enqueue = function (args) {
    intercomWrapper.queue.push(args);
  };

  window.Intercom = intercomWrapper;
}

// Función para cargar el script de Intercom
function loadIntercomScript() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://widget.intercom.io/widget/' + window.intercomSettings.app_id;
  const x = document.getElementsByTagName('script')[0];
  x.parentNode.insertBefore(script, x);
}

// Función para ver si ya se puede cargar el script de Intercom
function checkDocumentState() {
  if (document.readyState === 'complete') {
    loadIntercomScript();
  }
  else if (window.attachEvent) {
    window.attachEvent('onload', loadIntercomScript);
  }
  else {
    window.addEventListener('load', loadIntercomScript, false);
  }
}

// Función para inicializar Intercom
function initializeIntercom() {
  if (typeof window.Intercom === 'function') {
    attachIntercomActivator();
  }
  else {
    createIntercomInstance();
    checkDocumentState();
  }
}

// ********** Funciones para marcaje Amplitude **********

// Función para lanzar la marca de Amplitude al presionar el widget de Intercom
function handleLauncherWidgetClick() {
  setStyleLauncherWidget();
  const chatWidget = document.getElementsByClassName('intercom-messenger-frame')[0];
  const minimizeButton = document.getElementsByClassName('intercom-launcher-frame')[0].parentElement;
  if(chatWidget && messengerClosed) {
    messengerClosed = false;
  }
  else {
    $('#communication_employee_service').trigger('amplitudeTracking', { message: 'Open_Employee_Service_Chat' });
    messengerClosed = true;
  }
  setPositionLauncherAndChat(chatWidget, minimizeButton);
}

// Función para determinar cuándo está en el DOM el messenger de Intercom para añadir el evento.
function onIntercomLauncherRendered(mutationsList, observer) {
  mutationsList.forEach((mutation) => {
    if (mutation.type === 'childList') {
      setPositionLauncherWidgetAfterRendered(observer);
    }
  });
}

// Función para determinar si el chat de Intercom está abierto
function setWidgetOpenEvent() {
  onmount('.intercom-lightweight-app-launcher', function () {
    if(!firstEventFired) {
      const launcherWidget = document.getElementsByClassName('intercom-lightweight-app-launcher')[0];
      launcherWidget.addEventListener('click', handleLauncherWidgetClick);
      firstEventFired = true;
    }
  });

  const observer = new MutationObserver(onIntercomLauncherRendered);
  const targetNode = document.body;
  const observerConfig = { childList: true, subtree: true };
  observer.observe(targetNode, observerConfig);
  requestAnimationFrame(continuouslyApplyStyles);
}

// ********** Funciones de movimiento del Widget **********
const breakpoints = {
  md: window.matchMedia('screen and (min-width: 959px)'),
  xs: window.matchMedia('screen and (max-width: 958px)'),
};
const positions = {
  launcher: {
    md: { x: null, y: '8px' },
    xs: { x: null, y: '90px' },
  },
  launcherWithFresh: {
    md: { x: '27px', y: '15px' },
    xs: { x: null, y: '90px' },
  },
  chat: {
    md: { x: null, y: '94px' },
    xs: { x: null, y: '154px' },
  },
  chatWithFresh: {
    md: { x: '30px', y: '66px' },
    xs: { x: null, y: '140px' },
  },
};

Object.keys(breakpoints).forEach((key) => {
  breakpoints[key].addEventListener('change', function (event) {
    if (event.matches) {
      setPositionLauncherWidget({ hashEnabledFresh: document.body.dataset.enableFresh === 'true' });
      setPositionLauncherWidgetAfterRendered();
    }
  });
});

// Función para mover el chat de Intercom
function onReadyLauncher(mutationsList, observer) {
  mutationsList.forEach((mutation) => {
    if (mutation.type === 'childList') {
      setPositionLauncherWidget({ observer: observer, hashEnabledFresh: document.body.dataset.enableFresh === 'true' });
    }
  });
}

// Función para determinar si es necesario mover el chat de Intercom ya que Fresh esta prendido
function setWidgetMoveInitalEvent() {
  const observer = new MutationObserver(onReadyLauncher);
  const targetNode = document.body;
  const observerConfig = { childList: true, subtree: true };
  observer.observe(targetNode, observerConfig);
}

// Función para cambiar la posición del primer widget de Intercom si el widget de Copilot está habilitado
function setWidgetsPositionsCopilotInitial() {
  onmount('.intercom-lightweight-app-launcher', function () {
    const launcherWidget = document.getElementsByClassName('intercom-lightweight-app-launcher')[0];
    launcherWidget.style.transition = 'right 0.3s ease-in-out';
    launcherWidget.style.right = '420px';
  });
}

// Función para cambiar la posición del segundo widget de Intercom si el widget de Freshdesk está habilitado
function setPositionLauncherAndChat(chatWidget, minimizeButton) {
  chatWidget.style.transition = 'bottom 0.3s ease-in-out';
  minimizeButton.style.transition = 'bottom 0.3s ease-in-out';

  const isEnabledFresh = document.body.dataset.enableFresh === 'true';
  const chatPosition = isEnabledFresh ? positions.chatWithFresh : positions.chat;
  const launcherPosition = isEnabledFresh ? positions.launcherWithFresh : positions.launcher;
  // Aplica la posición al chatWidget y minimizeButton
  chatWidget.style.bottom = breakpoints.md.matches ? chatPosition.md.y : chatPosition.xs.y;
  chatWidget.style.right = breakpoints.md.matches ? chatPosition.md.x : chatPosition.xs.x;
  minimizeButton.style.bottom = breakpoints.md.matches ? launcherPosition.md.y : launcherPosition.xs.y;
}

// Función para cambiar la posición del segundo widget de Intercom si el widget de Copilot está habilitado
function setWidgetsPositionsCopilot(chatWidget, minimizeButton) {
  chatWidget.style.transition = 'right 0.3s ease-in-out';
  chatWidget.style.right = '420px';
  minimizeButton.style.transition = 'right 0.3s ease-in-out';
  minimizeButton.style.right = '420px';
}

function setPositionLauncherWidget(fields) {
  const launcherWidget = document.getElementsByClassName('intercom-lightweight-app-launcher')[0];
  const isEnabledFresh = document.body.dataset.enableFresh === 'true';
  const launcherPosition = isEnabledFresh ? positions.launcherWithFresh : positions.launcher;
  if (launcherWidget) {
    launcherWidget.style.transition = 'bottom 0.3s ease-in-out';
    launcherWidget.style.bottom = breakpoints.md.matches ? launcherPosition.md.y : launcherPosition.xs.y;
    launcherWidget.style.right = breakpoints.md.matches ? launcherPosition.md.x : launcherPosition.xs.x;
    if (fields['observer'] !== undefined && fields.observer) fields.observer.disconnect();
  }
}

function setPositionLauncherWidgetAfterRendered(observer = null) {
  const intercomFrame = document.getElementsByClassName('intercom-launcher-frame')[0];
  setStyleLauncherWidget();

  if (intercomFrame) {
    const chatWidget = document.getElementsByClassName('intercom-messenger-frame')[0];
    const minimizeButton = document.getElementsByClassName('intercom-launcher-frame')[0].parentElement;
    intercomFrame.contentDocument.body.addEventListener('click', handleLauncherWidgetClick);
    setPositionLauncherAndChat(chatWidget, minimizeButton);

    if(localStorage.getItem('copilotActive') === 'true') {
      setWidgetsPositionsCopilot(chatWidget, minimizeButton);
    }

    if (observer) observer.disconnect();
  }
}
/*
  * Debido a que intercom posee estilos propios que no se pueden modificar directamente, se creó esta función
  * para poder modificar los estilos del widget para los eventos de hover y click que cambian por defecto.
*/
function continuouslyApplyStyles() {
  if (window.matchMedia('(max-width: 767px)').matches) {
    setStyleLauncherWidget();
    requestAnimationFrame(continuouslyApplyStyles);
  }
}

function setStyleLauncherWidget() {
  const intercomLauncher = $('.intercom-launcher');
  const iconLauncher = intercomLauncher.find('.intercom-lightweight-app-launcher-icon');
  const iconLaunchersvg = iconLauncher.find('svg');
  const iframeOpen = $('.intercom-launcher-frame');
  const iframeContent = iframeOpen.contents();
  const intercomLauncherOpen = iframeContent.find('.intercom-launcher');
  const iconLauncherOpen = intercomLauncherOpen.find('svg');
  const launcherAfterclick = $('div[class^="intercom-with-namespace-"]');
  intercomLauncher.css('width', '40px');
  intercomLauncher.css('height', '40px');
  iconLauncher.css('width', '40px');
  iconLauncher.css('height', '40px');
  iconLaunchersvg.css('width', '16px');
  iconLaunchersvg.css('height', '16px');
  intercomLauncherOpen.css('width', '40px');
  intercomLauncherOpen.css('height', '40px');
  intercomLauncherOpen.css('bottom', '58px');
  iconLauncherOpen.css('width', '16px');
  iconLauncherOpen.css('height', '16px');
  iframeOpen.css('transform', 'translate(0px,8px)');
  launcherAfterclick.css('bottom', '90px');
  launcherAfterclick.css('z-index', '1000');
}

// Función para setear la posición original del widget de Intercom para prevenir cualquier comportamiento
// aleatorio
function setInitialWidgetOriginalPosition() {
  onmount('.intercom-lightweight-app-launcher', function () {
    const launcherWidget = document.getElementsByClassName('intercom-lightweight-app-launcher')[0];
    launcherWidget.style.right = '30px';
    launcherWidget.bottom.right = '29px';
  });
}

onmount('#communication_employee_service', function () {
  setIntercomSettings();
  initializeIntercom();
  setWidgetOpenEvent();
  setInitialWidgetOriginalPosition();

  setWidgetMoveInitalEvent();

  if(localStorage.getItem('copilotActive') === 'true') { setWidgetsPositionsCopilotInitial(); }
});
