/**
 * @file Realiza un slideshow de imágenes en el portal empleados. El slideshow se
 * maneja con flechas de izquierda y derecha y tiene unos puntos que indican la
 * posición actual de una imagen en el slideshow.
 * Tambien hace que las imágenes vayan cambiandose de forma automatica cada 5
 * segundos, a menos de que el parámetro data-statics sea true.
 */
import onmount from 'onmount';

onmount('.slide-list', function (obj) {
  const slides = Array.from(this.getElementsByClassName('slide'));
  const dots = Array.from(this.getElementsByClassName('dot'));
  const statics = this.getAttribute('data-statics');
  var currentSlide = 0;
  deactivateSlides(slides, dots);

  slides[0].style.display = null;
  dots[0]?.classList.add('dot-active');
  activateTimeout(obj, carrousel, statics);

  $(`.slide-list .next`).click(function () {
    const target = (currentSlide + 1) % slides.length;
    currentSlide = target;
    deactivateSlides(slides, dots);
    activateSlide(slides, dots, target);
    clearTimeout(obj.timeout);
    activateTimeout(obj, carrousel, statics);
  });

  $(`.slide-list .prev`).click(function () {
    let target = currentSlide - 1;
    if (target < 0) {
      target = slides.length - 1;
    }
    currentSlide = target;
    deactivateSlides(slides, dots);
    activateSlide(slides, dots, target);
    clearTimeout(obj.timeout);
    activateTimeout(obj, carrousel, statics);
  });

  function carrousel() {
    currentSlide = (currentSlide + 1) % slides.length;
    deactivateSlides(slides, dots);
    activateSlide(slides, dots, currentSlide);
    activateTimeout(obj, carrousel, statics);
  }
}, function (obj) {
  // Limpiamos los timeouts cuando desmontemos el slider
  if (obj.timeout) {
    clearTimeout(obj.timeout);
  }
});

function deactivateSlides(slides, dots) {
  slides.forEach((slide) => {
    slide.style.display = 'none';
  });
  dots.forEach((dot) => {
    dot.classList.remove('dot-active');
  });
}

function activateSlide(slides, dots, index) {
  slides[index].style.display = '';

  // Necesitamos que el circulo de indice se mantenga
  // en el del centro al moverse entre slides
  // cuando hay un máximo de 3 puntos.
  if (dots.length < 4) {
    if (index === 0) {
      dots[0].classList.add('dot-active');
    }
    else if ((index === slides.length - 1) && (slides.length > 2)) {
      dots[2].classList.add('dot-active');
    }
    else {
      dots[1].classList.add('dot-active');
    }
  }
  else {
    dots[index].classList.add('dot-active');
  }
}

function activateTimeout(obj, carrousel, statics) {
  if (!statics) {
    obj.timeout = setTimeout(carrousel, 5000);
  }
}
