/** @file
*
* Se encarga de homologar los estilos de los datatables mientras se migran las vistas a fiji.
* TODO: una vez migradas todas las vistas, eliminar este archivo.
*/
import onmount from 'onmount';

/**
 * Método para homologar los estilos de los datatables.
 *
 * @param {Array} classToHomologate clases que se homologarán.
 * @param {HTMLElement} tableResposive elemento que contiene la tabla bootstrap.
 * @param {Array} classList lista de clases del elemento table que se homologará.
 */

function homologateFijiStyles({ element, classToHomologate }) {
  const classList = element.attr('class').split(/\s+/);
  element.addClass('fiji-table');
  classList.forEach((className) => {
    if (classToHomologate.includes(className)) {
      element.removeClass(className);
    }
  });
  element.find('tfoot')?.remove();
}

/**
* @param {Function} homologateTables función que homologa los estilos de las tablas.
* @param {String} selectorItem selector de las tablas a homologar.
* @param {Boolean} urlEmployee indica si la url actual es ficha de empleados, no aplica estilos.
*/

function homologateTables(tableArrayElements) {
  const urlListToAvoid = ['employees', 'portal'];
  const urlToAvoid = urlListToAvoid.some((url) => window.location.href.includes(url));
  const isTableInCkeditor = $('.ck-editor').find('table').length > 0;
  if (urlToAvoid || isTableInCkeditor) {
    return;
  }
  tableArrayElements.forEach(function (selectorItem) {
    if($(selectorItem).length === 0) return;
    $(selectorItem).each(function () {
      homologateFijiStyles({
        element: $(this),
        classToHomologate: ['table-bordered', 'table-striped', 'table'],
      });
    });
  });
}

/**
* @param {Function} homologateTables función que homologa los estilos de las tablas.
*/

onmount('.dataTable, .table', function () {
  homologateTables(['.dataTable', '.table']);
});
