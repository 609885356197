/** @file TODO: documentar */
import onmount from 'onmount';
import { once, merge } from 'lodash-es';

const windowLoad = $.Deferred();

$(window).on('load', () => windowLoad.resolve());

const addScript = once(function addScript() {
  const script = document.createElement('script');
  script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
  script.defer = true;
  script.async = true;

  document.body.appendChild(script);
});

if (!window.disableBeamer) {
  onmount('#beamer-news', function () {
    const basicData = {
      product_id: 'XLZlrlKD16321',
      selector: '#beamer-news',
      language: 'ES',
      counter: false,
      mobile: false,
      display: 'right',
      callback: function (num) {
        if (num > 0) {
          $('#beamer-news .chip.news-count').text(num).removeClass('d-none');
        }
      },
    };
    const extraData = $(this).data('extraBeamer') || {};
    window.beamer_config = merge(basicData, extraData);
    windowLoad.then(addScript);
  });
}
